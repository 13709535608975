import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { SharedHttpService } from "./shared-http.service";
import { StorageService } from "./storage.service";
import { ActivatedRoute, Router, RoutesRecognized, UrlTree } from "@angular/router";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { LandingPageService } from './landing-page.service';
import { ClevertapService } from './clevertap.service';
import { filter, pairwise, map } from 'rxjs/operators';
import { DomSanitizer, Title } from "@angular/platform-browser";
import { FormGroup } from "@angular/forms";
import { CONST } from "../config/shared.constant";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { environment } from "../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { CommonPopupComponent } from "../components/common-popup/common-popup.component";
import * as CryptoJS from 'crypto-js';
import { CookieService } from "./cookie.service";

declare function callLoadingFunctions(): any;
declare function fractionSlider(): any;
declare function swipertab(): any;
declare function loadMore(): any;
declare function normalSlider(): any;
declare function normalSlider3(): any;
declare function expandcollapse(): any;
declare function customDropdown(): any;
declare function googleMaps(): any;
declare function loader(): any;
declare function accordianWithSlider(slideName: string): any;
declare function venueList(): any;
declare function rangeSlider(sliderName: string): any;
declare function sidebarDrop(): any;
declare function dropdownActiveClass() : any;
declare function videoPopup(): any;
declare function pressReleaseKitClick() : any;
declare function dateTimePicker(): any;
declare function multFilterWrapper(): any;
declare function customAccordionToggler(): any;
declare function customLegalToggler(): any;
declare function gardenWhatsOnFilter(): any;
declare function preSalesJS(): any;
declare function callNavFunction(): any;
declare function navigationScroll(): any;
declare function hideSecnavbar(): any;
declare function removeSecnavbarClass(): any;
declare function mobileSubnavigationText(): any;
declare function windowNotification(): any;
declare function windowNotificationClose(): any;
declare function dateTimePicker(startDate, endDate): any;
@Injectable({ providedIn: "root" })
export class SharedService {
  private userImage: BehaviorSubject<string> = new BehaviorSubject<any>(
    null
  );
  private previousUrl: any;
  private currentUrl: any;
  fragment!: string;
  previousRouteData: RoutesRecognized[] | { state: { root: { firstChild: { data: { page: any; }; }; }; }; }[] | any;
  public inquiryApiErrorMsg = new Subject<string>();
  public disableSubmitBtn = new Subject<boolean>();
  player360Script!: HTMLScriptElement;
  checkBrowser = false;
  customInitialize:boolean = false;
  constructor(
    private _httpService: SharedHttpService,
    private _storageService: StorageService,
    private _router: Router,
    private _landingPageService: LandingPageService,
    private _clevertapService: ClevertapService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title, 
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private documentInj: any,
    @Inject(PLATFORM_ID) platformId: string,
    private storageService: StorageService,
    public dialog: MatDialog
  ) {
    this.checkBrowser = isPlatformBrowser(platformId);

    if (this.checkBrowser) {
      const preferences = this.getCookieService()?.getPreferences()
      this.getCookieService()?.addGoogleAnalyticsScript('G-R966NFY646');
      this.getCookieService()?.addGtmScript('GTM-5JX9G7S');
      this.getCookieService()?.loadScripts(preferences)
    }

    activatedRoute.fragment.subscribe((fragment: any) => {
      this.fragment = (fragment) ? fragment : '';
      if (!this.fragment && this.checkBrowser) window.scroll(0, 0);
    });

    _router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise(), )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousRouteData = events;
      });
  }

  load360Function() {
    this.player360Script = document.createElement('script');
    this.player360Script.src = "assets/vendor/js/360.js";
    this.player360Script.type = 'module';
    document.getElementsByTagName('body')[0].appendChild(this.player360Script);
  }

  getHttpService() {
    return this._httpService;
  }

  getStorageService() {
    return this._storageService;
  }

  getCommonService() {
    return this._landingPageService;
  }
  getCleverTapService() {
    return this._clevertapService;
  }
  getCookieService() {
    return this.cookieService;
  }
    
  initializeCustomJs() {
    if (this.checkBrowser) {
      this.customInitialize = true;
      setTimeout(() => {
        callLoadingFunctions();
        this.scroll();
      }, 1000)
    }
  }

  callFractionSlider() {
     if (this.checkBrowser) {
       setTimeout(() => {
         fractionSlider();
       }, 200)
     }
    }

  callSwipertab() {
    if (this.checkBrowser) {
      setTimeout(() => {
        swipertab();
      }, 200)
    }
  }

  callNormalSlider3() {
    if (this.checkBrowser) {
      setTimeout(() => {
        normalSlider3();
      }, 200)
    }
  }
  callNormalSlider() {
      if (this.checkBrowser) {
        setTimeout(() => {
          normalSlider();
        }, 200)
      }
    }
  callCustomDropdown() {
    if (this.checkBrowser) {
      setTimeout(() => {
        customDropdown();
      }, 200);
    }
  }

  callGoogleMaps() {
      if (this.checkBrowser) {
        setTimeout(() => {
          googleMaps();
        }, 200);
      }
    }

  callLoadMore() {
    if (this.checkBrowser) {
      setTimeout(() => {
        loadMore();
      }, 200)
    }
  }

  callExpandcollapse() {
    if (this.checkBrowser) {
      setTimeout(() => {
        expandcollapse();
      }, 200)
    }
  }
  callLoader() {
      if (this.checkBrowser) {
        setTimeout(() => {
          loader();
        }, 200)
      }
    }
  callAccordianWithSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        accordianWithSlider("accordian_slider");
      }, 200)
    }
  }
  callVenueList() {
    if (this.checkBrowser) {
      setTimeout(() => {
        venueList();
      }, 200)
    }
  }
  callPeopleRangeSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        rangeSlider("peopleRangeSlider");
      }, 200)
    }
  }
  callsidebarDrop() {
      if (this.checkBrowser) {
        setTimeout(() => {
          sidebarDrop();
        }, 200)
      }
    }
  calldropdownActiveClass() {
      if (this.checkBrowser) {
        setTimeout(() => {
          dropdownActiveClass();
        }, 200)
      }
    }
  callVideoPopup() {
    if (this.checkBrowser) {
      setTimeout(() => {
        videoPopup();
      }, 200)
    }
  }
  callPressReleaseKitClick() {
    if (this.checkBrowser) {
      setTimeout(() => {
        pressReleaseKitClick();
      }, 200)
    }
  }

  callCustomDateTimePicker(){
    if (this.checkBrowser) {
      setTimeout(() => {
        dateTimePicker();
      }, 200)
    }
  }


  callMultFilterWrapper() {
    if (this.checkBrowser) {
      setTimeout(() => {
        multFilterWrapper();
      }, 200)
    }
  }

  callCustomAccordionToggler(){
    if (this.checkBrowser) {
      setTimeout(() => {
        customAccordionToggler();
      }, 200)
    }
  }

  callcustomLegalToggler(){
    if (this.checkBrowser) {
      setTimeout(() => {
        customLegalToggler();
      }, 200)
    }
  }
  callGardenWhatsOnFilter(){
    if (this.checkBrowser) {
      setTimeout(() => {
        gardenWhatsOnFilter();
      }, 200)
    }
  }

  callPreSalesJS(){
    if (this.checkBrowser) {
      setTimeout(() => {
        preSalesJS();
      }, 200)
    }
  }

  callNavFunction(){
    if (this.checkBrowser) {
      setTimeout(() => {
        callNavFunction();
      }, 200)
    }
  }

  callNavigationScroll(){
    if (this.checkBrowser) {
      setTimeout(() => {
        navigationScroll();
      }, 200)
    }
  }

  callHideSecnavbar(){
    if (this.checkBrowser) {
      setTimeout(() => {
        hideSecnavbar();
      }, 200)
    }
  }

  callRemoveSecnavbarClass(){
    if (this.checkBrowser) {
      setTimeout(() => {
        removeSecnavbarClass();
      }, 200)
    }
  }

  callMobileSubnavigationText(){
    if (this.checkBrowser) {
      setTimeout(() => {
        mobileSubnavigationText();
      }, 200)
    }
  }

  callWindowNotification(){
    if (this.checkBrowser) {
      setTimeout(() => {
        windowNotification();
      }, 200)
    }
  }

  callWindowNotificationClose(){
    if (this.checkBrowser) {
      setTimeout(() => {
        windowNotificationClose();
      }, 200)
    }
  }

  callCustomDateTimePicker2(startDate, endDate){
    if (this.checkBrowser) {
      setTimeout(() => {
        dateTimePicker(startDate, endDate);
      }, 200)
    }
  }

  checkCTALink($event: { preventDefault: () => void; }, link?: string | URL | UrlTree | undefined | any, target = '_self', type = 'Internal', fileName?: string) {
    if (!link || link == '#') {
      $event.preventDefault();
    } else if (target == '[\"_blank\"]' || target == '_blank' || target == '[\"_self\"]' ||target == '_self') {
      target == '[\"_self\"]' || target == '_self' ? window.open(link, '_self') : window.open(link, target);
    } else if (type == 'PDF') {
      let anchorLink: any = document.createElement("a");
      anchorLink.download = fileName;
      anchorLink.href = link;
      anchorLink.click();
    }
    else {
      this._router.navigateByUrl(link);
    }
    return false;
  }

  subHeaderItems(titleList?: any[]) {
    let subHeaderList: { id: any; title: any; }[] = [];
    if (!titleList) {
      titleList = [
        "Exhibition Halls", "Floor Plans", "Highlights", "Planning Tools", "Events", "Showcase"
      ];
    }
    titleList.forEach((title: string, index: any) => {
      let obj = { id: (title.replace(/\s/g, '-')).toLowerCase(), title: title }
      subHeaderList.push(obj);
    });
    return subHeaderList;
  }

  getPdfName(string: string | string[]) {
    if (string) {
      let start = string.lastIndexOf('/') + 1;
      let end = string.indexOf('.');
      let pdfName = string.slice(start, end);
      // // console.log("pdfname: ", pdfName);
      return pdfName;
    }
  }

//   downloadAllDocuments(files: any[], filename: string){
//     // // console.log("files", files);
//     let count = 0;
//     const zipFilename = filename+".zip";
//     // Assign new zip object
//     let zip = new JSZip()
//      // Loop through the filelist to get each filename and pass each file to zip object
//   //    for(let file of files){ 
//   //     let start = file.lastIndexOf('/') + 1;
//   //     let filename=file.slice(start);
//   //     zip.file(filename, file)
//   //     // console.log("filename: ", filename);
//   // }
//    // Generate the complete zip file
//   //  zip.generateAsync({type:'blob'}).then((blobdata)=>{
//     // create zip blob file
//     // let zipblob = new Blob([blobdata])

//     // For development and testing purpose
//     // Download the zipped file 
// //     var elem = window.document.createElement("a")
// //     elem.href = window.URL.createObjectURL(zipblob)
// //     elem.download = 'compressed.zip'
// //     elem.click()
// // })
// files.forEach(async function (url: string){
//   const urlArr = url.split('/');
//   // // console.log("urlArr", urlArr);
//   const filename = urlArr[urlArr.length - 1];
//   // // console.log("filename", filename);
//   try {
//     const file = await JSZipUtils.getBinaryContent(url)
//     zip.file(filename, file, { binary: true});
//     count++;
//     if(count === files.length) {
//       zip.generateAsync({type:'blob'}).then(function(content: any) {
//          saveAs(content, zipFilename);
//       });
//     }
//   } catch (err) {
//     // console.log(err);
//   }
// });
// }

  logout() {
    // this.cookieService.delete("USER", "/", ".ril.com");
    // this.cookieService.delete("MYSSO", "/", ".ril.com");
    // this.cookieService.deleteAll();
    // sessionStorage.clear();
    // this._router.navigate(["/login"]);

    // this._httpService
    //   .getWSO2("loginUrl", CONST["apiEndPoint"]["logout"], null)
    //   .subscribe(
    //     (resp: any) => {
    //       this._router.navigate(["/login"]);
    //     },
    //     err => {
    //       this._router.navigate(["/login"]);
    //     }
    //   );
  }

  fetchToken() {
    this.getHttpService()
      .getToken("loginUrl", CONST["apiEndPoint"]["gettoken"])
      .subscribe(
        (response: any) => {
          this.getStorageService().setSessionData("Wso2Token", response);
          this.getImage();
          this._router.navigate(["/dashboard"]);
        },
        (error: any) => { }
      );
  }

  getImage() {
    // let obj = {
    //   id: this._storageService.getUserCookie()
    //     ? this._storageService.getUserCookie().domain_id
    //     : null,
    //   soapAction: "InfoByDomainID"
    // };
    // this.getHttpService()
    //   .postWSO2("wso2Url", CONST["apiEndPoint"]["userImage"], {
    //     id: "ankit.panchal",
    //     soapAction: "InfoByDomainID"
    //   })
    //   .subscribe(
    //     res => {
    //       if (res && res["ImageResponse"] && res["ImageResponse"].length > 0) {
    //         this.userImage.next(
    //           `data:image/png;base64,${res["ImageResponse"]}`
    //         );
    //       } else {
    //         this.userImage.next(null);
    //       }
    //     },
    //     err => {
    //       this.userImage.next(null);
    //     }
    //   );
  }

  getUserImage() {
    return this.userImage;
  }

  public getPreviousUrl() {
    if (this.previousRouteData) return this.previousRouteData[0];
    
    
  }
  public getCurrentUrl() {
    if (this.previousRouteData) return this.previousRouteData[1];
    
    
  }
  public getPreviousRouteData() {
    if (this.previousRouteData) {
      return this.previousRouteData[0].state.root.firstChild.data.page ? this.previousRouteData[0].state.root.firstChild.data.page : 'home';
    } else {
      return 'home';
    }
  }

  scroll() {
    if (this.fragment) {
      const elmnt: any = document.getElementById(this.fragment);
      elmnt?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  submitInquiryForm(payload: any, enquiryFormCTEventObj: any, profile: any, formData?: any) {
    this.disableSubmitBtn.next(true);
    if (formData) {
      this.getCommonService().submitCareersInquiryDetails(payload).subscribe(
        (data: any) => {
          this.disableSubmitBtn.next(false);
          this.getCleverTapService().createCTUserLogin(profile);
          this.getCleverTapService().recordSubscriptionEvent('Enquiry_Forms', enquiryFormCTEventObj);
          // // console.log(enquiryFormCTEventObj);
          this._router.navigate(['./thank-you'], {
            state: { inqId: data.data }
          });
          // this.resetInquiryFormValues();
        },
        (error) => {
          this.disableSubmitBtn.next(false);
          // console.log("Try after some time..");
          this.inquiryApiErrorMsg.next(error);
        }
      )
    } else {
      this.getCommonService().submitInquiryDetails(payload).subscribe(
        (data: any) => {
          this.disableSubmitBtn.next(false);
          this.getCleverTapService().createCTUserLogin(profile);
          this.getCleverTapService().recordSubscriptionEvent('Enquiry_Forms', enquiryFormCTEventObj);
          // // console.log("On Success:", enquiryFormCTEventObj);
          this._router.navigate(['./thank-you'], {
            state: { inqId: data.data }
          });
          // this.resetInquiryFormValues();
        },
        (error) => {
          this.disableSubmitBtn.next(false);
          // // console.log("On Error:", enquiryFormCTEventObj);
          // console.log("Try after some time..");
          this.inquiryApiErrorMsg.next(error);
        }
      )
    }
  }
  resetInquiryFormValues() {
    this.getStorageService().setSessionData('name', '');
    this.getStorageService().setSessionData('email', '');
    this.getStorageService().setSessionData('company', '');
    this.getStorageService().setSessionData('message', '');
    this.getStorageService().setSessionData('phoneNo', '');
  }
  setInquiryFormValues(name: any, email: any, company: any, message: any, phoneNo?: any) {
    if (name) this.getStorageService().setSessionData('name', name);
    if (email) this.getStorageService().setSessionData('email', email);
    if (company) this.getStorageService().setSessionData('company', company);
    if (message) this.getStorageService().setSessionData('message', message);
    if (phoneNo) this.getStorageService().setSessionData('phoneNo', phoneNo);
  }
  getInquiryFormValues() {
    let obj = {
      name: this.getStorageService().getSessionData('name'),
      email: this.getStorageService().getSessionData('email'),
      company: this.getStorageService().getSessionData('company'),
      message: this.getStorageService().getSessionData('message'),
      phoneNo: this.getStorageService().getSessionData('phoneNo')
    }
    return obj;
  }
  callCTEvent(eventName: string, sectionName?: any, contentName?: any, staticLink?: any){
    if (this.checkBrowser) {
      let pageSource = this.titleService.getTitle();
      setTimeout(() => {
        // let previousUrl = this._sharedService.getPreviousUrl();
        let currentUrl: any = this.getCurrentUrl();
        let eveObj: any = {
            Page_Source: (pageSource == 'Jio World Centre - Get Directions') ? 'Jio World Centre - Visit Us' : pageSource,
            Event_Name: eventName
        };
        if (eventName != 'Download_Links') {
          if (staticLink){
            eveObj['Link_Clicked'] = staticLink;
          }else if(eventName == 'Header_Primary_Links'){
            eveObj['Link_Clicked_Value'] = currentUrl.state.root.firstChild.data.CTLinkClicked;
          }else {
            eveObj['Link_Clicked'] = currentUrl.state.root.firstChild.data.CTLinkClicked;
          }
        };
        if (sectionName) eveObj['Section_Name'] = sectionName;
        if (contentName) eveObj['Content_Name'] = contentName;
          // this.getCleverTapService().recordSubscriptionEvent(eventName, eveObj);
        // // console.log(eveObj);
      }, 500)
    }
  }
  updateSubscriptionCTInfo(data: { data: { mailId: any; unsubscriptionUrl: any; }; }, subscriptionFlag: any, subscriptionEventName: any) {
    if (data.data) {
      let emailId = data.data.mailId;
      let unsubscriptionLink = data.data.unsubscriptionUrl;
      let eventObj = {};
      let updatedProfile: any = {
        Email: emailId
      };
      // // console.log(updatedProfile);
      if (subscriptionFlag) {
        updatedProfile['Unsubscription Link'] = unsubscriptionLink;
        eventObj = {
          Act: "Y",
          Unsubscribe_link: unsubscriptionLink
        }
      } else {
        updatedProfile['Unsubscription Link'] = "";
        updatedProfile['MSG-email'] = false;
        eventObj = {
          Act: "N",
          Unsubscribe_link: ""
        }
      }
      this.getCleverTapService().createCTUserLogin(updatedProfile);
      this.getCleverTapService().recordSubscriptionEvent(subscriptionEventName, eventObj);
      // // console.log(eventObj);
    }
  }
  handleFileInput(fileObject: string | any[], form: FormGroup | any, fileSizeLimit: any, fileExtension: any) {
    // // console.log("fileObject", fileObject);
    let fileSizeValid;
    let fileTypeValid;
    if (fileObject.length > 0) {
      let attachedFile = fileObject[0];
      fileSizeValid = this.isFileSizeValid(attachedFile, fileSizeLimit);
      fileTypeValid = this.isFileTypeValid(attachedFile, fileExtension);
      if (fileSizeValid === "" && fileTypeValid === "") {
        form.patchValue({
          file: attachedFile
        });
        form.get('file').updateValueAndValidity();
      } else {
        form.patchValue({
          file: ''
        });
      }
    }
    return form;
  }
  isFileSizeValid(fileObj: { size: number; }, fileSizeLimitInBytes: number) {
    let totalSizeMB = fileSizeLimitInBytes / Math.pow(1024, 2);
    if (fileSizeLimitInBytes !== -1 && fileObj.size >= fileSizeLimitInBytes) {
      // this.fileSizeError = "File size limit should be " + Math.round(totalSizeMB) + "MB max";
      return "File size limit should be " + Math.round(totalSizeMB) + "MB max";
    } else if (fileObj.size == 0) {
      // this.fileSizeError = "File is empty";
      return "File is empty";
    } else {
      // this.fileSizeError = "";
      return "";
    }
  }

  isFileTypeValid(fileObj: { name: string; }, fileExtensions: string | string[]) {
    const fileExtn: string = fileObj.name.split(".").pop() as string;
    if (fileExtensions && fileExtensions.includes("." + fileExtn.toLowerCase())) {
      // this.fileTypeError = "";
      return "";
    }
    // this.fileTypeError = "Invalid file type";
    return "Invalid file type";
  }
  
  getSafeHTML(value: {} | string, addScriptTag = true) {

    const json = addScriptTag ? JSON.stringify(value, null, 2) : value;
    const html: string = addScriptTag ? `<script type="application/ld+json">${json}</script>` : json as string;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getBaseUrl(){
   return this.documentInj.location.origin;
  }

  getUrlPath(item, jsonPath?) {
    const key = Object.keys(item)[0];
    const value: any = Object.values(item)[0];
    return value.type === 'JSON' ? `/assets/config/${jsonPath}/${value.name}.json` : value.name;
  }

  prepareUrls(arr, type){
    return arr.map((item) => {
      return {name: item, type: type || 'JSON'};
    })
  } 

  CTClick(componentID) {
    if (this.checkBrowser && componentID) {
      let arr = [componentID]
      this.getCommonService().getlandingUrl(arr).subscribe((data: any) => {
        const CTEventData = data[0]?.data
        if (CTEventData?.IsActive?.data === 'true') {
          const input = {};
          CTEventData?.cards.forEach((property) => {
            input[property?.PropertyName?.data] = property?.PropertyValue?.data
          })
          if (input['Page_Source']) {
            this.getCleverTapService().recordSubscriptionEvent(CTEventData?.EventName?.data, input);
          }
          if (input['event']) {
            if(input['page_path']) input['page_path'] = this._router.url;
            this.getClickSource(input)
          }
        }
      },
        (error) => {
          console.log("Try after some time...");
        }
      )
    }
  }

  callActivatedRoute(activatedRoute){
    activatedRoute.fragment.subscribe((fragment: any) => {
       this.fragment = (fragment) ? fragment : '';
       if (!this.fragment && this.checkBrowser) window.scroll(0, 0);
     });
   }

   decryptToken(payload): Observable<any> {
    return this.getHttpService().postLogin('staticUrlForImgNew', CONST["apiEndPoint"]['decryptToken'], payload)
  }

  encryptToken(payload): Observable<any> {
    return this.getHttpService().postLogin('staticUrlForImgNew', CONST["apiEndPoint"]['encryptToken'], payload)
  }

  openMicrositeUrl(input, linkedin_id?) {
    this.encryptToken(input).subscribe((res: any) => {
      const link = `${environment.micrositeUrl}${res?.data?.data}`;
      if (linkedin_id) {
        // (<any>window).lintrk('track', { conversion_id: 14859225 })
        (<any>window).lintrk('track', { conversion_id: linkedin_id })
        console.log(linkedin_id,'linkedin_id');
      }
      window.open(link, '_self');  
      this.storageService.delelteSessionData('currShow');
    })
  } 

  openBookNowAsGuest(eventId, linkedin_id?) {
    const payload = {
      showId: eventId,
      Guest: 'Y'
    }
    this.openMicrositeUrl(payload, linkedin_id);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  getOpeningShowsData(pageComponent = 'Custom_Opening_Shows') {
    return this.getCommonService().getlandingUrl([pageComponent])
    .pipe(
      map((res: any) => {
        const mergeData = res?.filter((item: any) => item.data)?.map((item :any)=> {
          if (item?.data?.cards[0]?.AddCardDetails) {
            return item?.data?.cards?.map(element=>{
              return {...element, AddCardDetails: element?.AddCardDetails?.filter(card=> !card?.HideEventOnSite)}
            })
          }else{
            return item?.data?.cards?.filter(card=> !card?.HideEventOnSite)
          }
        });
        return mergeData?.[0];
      })
    )
  }

  getJioIdUserDetails(): Observable<any> {
    return this.getHttpService().postLogin('jioIdApiUrl', CONST["apiEndPoint"]['getJioIdUserDetails'], '','')
  }

  sendJioIdUserDetails(payload): Observable<any> {
    return this.getHttpService().postLogin('jioIdApiUrl', CONST["apiEndPoint"]['sendJioIdUserDetails'], payload)
  }

  verifyJioIdUserDetails(payload): Observable<any> {
    return this.getHttpService().postLogin('jioIdApiUrl', CONST["apiEndPoint"]['verifyJioIdUserDetails'], payload)
  }

  getStatusOfOpeningShowsItem(item) {
    if(!item.EventID || !item.SessionId) return of(null);
    const params = `$format=json&$filter=ScheduledFilmId eq '${item.EventID}' and SessionId eq '${item.SessionId}'`;
    return this.getHttpService().getNoCred('vistaWebClientUrl','getVistaSessionDetails', params, { ConnectApiToken : environment.vistaConnectApiToken });
  }

  crmAccessToken(payload): Observable<any> {
    return this.getHttpService().postLogin('crmUrl', CONST["apiEndPoint"]['crmAccessToken'], payload)
  }

  getContactCopy(payload): Observable<any> {
    // const params = `$select=contactid,fullname,emailaddress1,modifiedon &$filter=mobilephone eq '${payload}'`
    const params = {
      $select:'contactid,fullname,emailaddress1,mobilephone,modifiedon',
      $filter: payload }
    return this.getHttpService().getWSO2('jwcUrl', CONST["apiEndPoint"]['crmContacts'], params)
  }

  createUser(payload): Observable<any> {
    return this.getHttpService().postWSO2('jwcUrl', CONST["apiEndPoint"]['crmContacts'], payload, {$select : 'contactid'})
  }

  updateUser(contactid, payload): Observable<any> {
    const params = {
      $select: 'contactid,fullname,emailaddress1,modifiedon'
    }
    return this.getHttpService().patchWSO2('jwcUrl', CONST["apiEndPoint"]['crmContacts'] + `(${contactid})`, payload, params)
  }

  getJioIDActivityDetails(payload): Observable<any> {
    let headers = {
      'API_KEY': environment['api_key']
    }
    return this.getHttpService().postLogin('ipURL', CONST["apiEndPoint"]['getActivityDetails'], payload, '', headers)
  }

  private getHMACSHA256(text: string, key: string): string {
    // Create the HMAC SHA-256 hash
    const hash = CryptoJS.HmacSHA256(text, key);

    // Convert the hash to a hex string and return it
    return hash.toString(CryptoJS.enc.Hex).toUpperCase();
  }

  fonSendOtp(payload): Observable<any> {
    const checksum = this.getHMACSHA256(payload, environment['CheckSum_Key']);
    const endpoint = this.isProd() ? 'fonSendOtpForProd' : 'fonSendOtp'
    const headers = {
      'X-Checksum': checksum,
      'Authorization': environment['fonAuthorization'],
      'Content-Type': 'application/json'
    };
    return this.getHttpService().postLogin('loyaltyUrl', CONST["apiEndPoint"][endpoint], payload, '', headers)
  }

  fonVerifyOtp(payload): Observable<any> {
    const checksum = this.getHMACSHA256(payload, environment['CheckSum_Key']);
    const endpoint = this.isProd() ? 'fonVerifyOtpForProd' : 'fonVerifyOtp'
    const headers = {
      'X-Checksum': checksum,
      'Authorization': environment['fonAuthorization'],
      'Content-Type': 'application/json'
    };
    return this.getHttpService().postLogin('loyaltyUrl', CONST["apiEndPoint"][endpoint], payload, '', headers)
  }

  isProd(){
    return (environment.env === 'prod' ? true : false)
  }

  
  getClickSource(input) {
    return (<any>window).dataLayer.push(input);
  }

  downloadFile(fileName) {
    let anchorLink: HTMLElement = document.createElement("a");
    anchorLink.setAttribute('href', fileName);
    anchorLink.setAttribute('download', fileName);
    anchorLink.click();
  }
}


import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MetaService } from "../meta.service";
import { SharedService } from "../shared.service";


@Injectable({
    providedIn: 'root'
})
export class BlogDetailsResolver implements Resolve<any> {
    staticUrlForImgNew: string = environment.staticUrlForImgNew;
    constructor(private _sharedService: SharedService,
        private customMetaService: MetaService,) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const slugUrl = route.params.slugUrl;
        return this._sharedService.getCommonService().getlandingUrl(['Blogs'])
            .pipe(map((res: any) => {
                const data = res[0]?.data;
                const otherBlogData = [];
                let currentBlogData: any

                for (let item of data) {
                    if (item.SlugURL === slugUrl) {
                        currentBlogData = item;
                    } else {
                        otherBlogData.push(item);
                    }
                }

                // const metaLogo = currentBlogData?.EventMetaLogo || currentBlogData?.MetaLogo;
                // this.customMetaService.updatePageMeta({
                //     metaTitle: currentBlogData?.MetaTitle,
                //     metaDesc: currentBlogData?.MetaDescription,
                //     metaLogo: metaLogo,
                //     script: currentBlogData?.SchemaScript,
                //     canonicalUrl: currentBlogData?.CanonicalURL,
                // });
                return {
                    currentBlogData,
                    otherBlogData
                };
            }))
    }

}
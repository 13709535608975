import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkSectionShow'
})
export class CheckSectionShowPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value?.HideComponentOnSite?.data ==='false';
  }

}

import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComingSoonComponent implements AfterViewInit, OnDestroy {
  data = {
    "HideComponentOnSite": {
        "data": "false"
    },
    "cards": [
        {
            "SecondCTADetails": {},
            "FirstCTAText": {
                "data": "Back to Home",
            },
            "UploadImageVideo": {
                "document": {
                    "contentUrl": "/assets/images/coming-soon.jpg",
                    "sizeInBytes": 412443,
                    "fileExtension": "jpg",
                    "description": "",
                    "encodingFormat": "image/jpeg",
                    "id": 667505,
                    "title": "Grand-Theatre-500X500.jpg",
                    "contentType": "Document"
                }
            },
            "SecondCTAText": {
                "data": "VIEW CALENDAR"
            },
            "PlaceholderImageForVideoUploaded": {
                "image": {
                    "contentUrl": "/assets/images/coming-soon.jpg",
                    "sizeInBytes": 102144,
                    "fileExtension": "jpg",
                    "description": "",
                    "encodingFormat": "image/jpeg",
                    "id": 540215,
                    "title": "2.jpg",
                    "contentType": "Document"
                }
            },
            "FirstHideCTA": {
                "data": "true"
            },
            "FirstCTADetails": {},
            "PlaceholderImageForMobileVideoUploaded": {
                "image": {
                    "contentUrl": "/assets/images/coming-soon(mobile).jpg",
                    "sizeInBytes": 151156,
                    "fileExtension": "jpg",
                    "description": "",
                    "encodingFormat": "image/jpeg",
                    "id": 539957,
                    "title": "the-cultural-centre-KMA-mobile-banner.jpg",
                    "contentType": "Document"
                }
            },
            "SecondHideCTA": {
                "data": "true"
            },
            "BackgroundImageVideo": {
                "data": "image"
            },
            "UploadMobileKMAImageVideo": {
                "document": {
                    "contentUrl": "/assets/images/coming-soon(mobile).jpg",
                    "sizeInBytes": 63908265,
                    "fileExtension": "mov",
                    "description": "",
                    "encodingFormat": "video/quicktime",
                    "id": 698947,
                    "title": "NMACC HQ (1).mov",
                    "contentType": "Document"
                }
            },
            "FirstCTALink": {
                "data": "https://www.google.com/"
            },
            "SecondCTALinkWindowPreference": {
                "data": "[\"_self\"]"
            },
            "FirstCTALinkWindowPreference": {
                "data": "[\"_blank\"]"
            },
            "FirstCTAType": {
                "data": "link"
            },
            "Headline": {
                "data": ""
            },
            "SecondCTAType": {
                "data": "video"
            },
            "ImageAltText": {
                "data": "cultural-centre-kma-video"
            },
            "ImageTintOn": {
                "data": "false"
            },
            "SubTitle": {
                "data": ""
            },
            "SecondCTALink": {
                "data": "#"
            }
        }
    ],
    "AutomaticSlideshowInterval": {
        "data": "[\"4\"]"
    }

    
}

ngAfterViewInit(): void {
  setTimeout((a)=>{
    let footer = document.querySelector('.jwc_footer_top');
  footer.setAttribute('style','display: none')
  },1000);
}
 ngOnDestroy(): void {
  let footer = document.querySelector('.jwc_footer_top');
  footer.removeAttribute('style')
 }
}

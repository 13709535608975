import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';
@Component({
  selector: 'app-you-may-like',
  templateUrl: './you-may-like.component.html',
  styleUrls: ['./you-may-like.component.css']
})
export class YouMayLikeComponent implements OnInit {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  @Input('openingShowsData') set setOpeningShowsData(value) {
    if (this.currEventType?.category) {
      this.openingShowsData = value?.filter((item) => item?.EventType?.category === this.currEventType?.category);
    } else {
      this.openingShowsData = value?.filter((item) => item?.CalendarPage)
    }
  };
  @Input() allData;
  @Input() currEventType;
  openingShowsData: any;
  classObject = {
    outerDiv: 'you_may_like_each',
    contentDiv_1: 'copy-container',
    contentDiv_2: 'you_may_like_each--copy content3',
    subtitleAnchor: 'nohover',
    ctaAnchor: 'w-189',
  }

  openingShowsConfig = {
    showImg: true,
    showTitle: true,
    showSubTitle: true,
    showDate: true,
    showVenue: true,
    showFirstCTA: true,
    showSecondCTA: true,
    showBookNowCTA: true,
  };

  constructor(private _sharedService: SharedService,) { }

  ngOnInit(): void {

  }

}
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-covid2-section',
  templateUrl: './covid2-section.component.html',
  styleUrls: ['./covid2-section.component.css']
})
export class Covid2SectionComponent implements OnInit {
  routerUrl;
  @Input() page;
  @Input() aosDelay?;
  @Input() watchVideo?;
  @Input() watchVideoUrl?;
  @Input() covidPageData?;
  videoUrl: any;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;

  constructor(private _sharedService: SharedService, private titleService: Title) {
   }

  ngOnInit() {
    this.routerUrl = (this.page=='garden')? '/jio-world-garden/visit-safely' : (this.page=='das') ? '/dhirubhai-ambani-square/visit-safely' : (this.page=='new-das') ? '/das/visit-safely' : '/health-and-covid-safety-measures';
  }

  callCTVisitSafelyEvent() {
    this._sharedService.callCTEvent('Visit_Safely_Links');
  }

}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.css']
})
export class ReadComponent implements OnInit, OnChanges {
  @Input() readsData
  @Input() pageName
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  articles;
  blogs;

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void { 
    let nMACCKeyArray = [
      'Blogs'
    ];

    this._sharedService.getCommonService().getlandingUrl(nMACCKeyArray)
    .pipe(
      map((data: any) => data?.[0]?.data || [])
    )
    .subscribe(
      (data: any) => {
        this.blogs = data;
        this.setPriority(this.blogs);
    })
  }

  ngOnChanges() {
    // this.setPriority(this.blogs)
  }

  setPriority(arr) {
    let order = ["true", "false"]
    this.articles = arr?.filter(obj => obj[this.pageName])
    // this.articles?.sort((x, y) => order.indexOf(x?.IsActive) - order.indexOf(y?.IsActive));
    // this.articles?.sort((x, y) => {
    //   let date1 = new Date(x?.Date)
    //   let date2 = new Date(y?.Date)
    //   return date1.getTime() - date2.getTime()
    // });
    // if (this.articles?.length > 3) { this.articles.length = 3 }
    // console.log(arr);
  }

}

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthGuardGuard } from './core/guards/auth-guard.guard';
import { InterceptorService } from './shared/interceptors/interceptor.service';
import { AppInitService } from './shared/services/app-init.service';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './modules/app-material/app-material.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    AppMaterialModule
  ],
  providers: [
    Title, 
    AppInitService,
    {​​​​ provide: APP_INITIALIZER, useFactory: init, deps: [AppInitService], multi: true }​​​​,
    {​​​​ provide: APP_INITIALIZER, useFactory: loadUrls, deps: [AppInitService], multi: true }​​​​,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    AuthGuardGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function init(appInitService: AppInitService) {​​​​ return () => appInitService.init();}​​​​
export function loadUrls(appInitService: AppInitService) {​​​​ return () => appInitService.loadUrls()};

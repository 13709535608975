import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventVenue'
})
export class EventVenuePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.EventVenue ? value.EventVenue.data : (value && value.IconLabel ? value.IconLabel.data : ( value && value.Venue ? value.Venue.data : ''));
  }

}

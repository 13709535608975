import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.css']
})
export class SpacesComponent implements OnInit {

  @Input() spacesData
  staticUrlForImgNew: string = environment.staticUrlForImgNew;

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
  }

}

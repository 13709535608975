import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerSubTitle'
})
export class HeaderSubTitlePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.ComponentHeaderSubTitle ? value.ComponentHeaderSubTitle.data : (value && value.HeaderSubTitle && value.HeaderSubTitle.data ? value.HeaderSubTitle.data : (value && value.TextBox1rhh && value.TextBox1rhh.data ? value.TextBox1rhh.data : (value?.ComponentDescription?.data ? value.ComponentDescription.data : '')));
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-vip-cards',
  templateUrl: './vip-cards.component.html',
  styleUrls: ['./vip-cards.component.css']
})
export class VipCardsComponent implements OnInit {

  @Input() VIPdata;
  @Input() IsSliding = false;
  staticUrlForImgNew = environment.staticUrlForImgNew;

  constructor(public _sharedService: SharedService, private storageService: StorageService) { }

  ngOnInit(): void {

  }

  redirectToReservationForm($event, link, windowPreference, restaurantName) {
    $event.preventDefault();
    // this.storageService.setSessionData('restaurantName', restaurantName);
    sessionStorage.setItem('restaurantName', restaurantName);
    this._sharedService.checkCTALink($event, link, windowPreference)
  }

}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-plan-big-event',
  templateUrl: './plan-big-event.component.html',
  styleUrls: ['./plan-big-event.component.css']
})
export class PlanBigEventComponent implements OnInit, OnChanges {
  @Input() eventsData;
  accordians
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService, private titleService: Title) {}

  ngOnInit() {}

  ngOnChanges(){
    this.accordians=this.eventsData?.cards?.filter(obj=>obj?.HideAccordian?.data==='false')
  }

  callPlanEventCTEvent(sectionTitle) {
    this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { CtaType } from '../../config/enum.config';
import { SharedService } from '../../services/shared.service';

const pageButtonTypes = {
  CTA: {
    linkKeyName: 'CTALink',
    hideKeyName: 'HideCTA',
    hideKeyName2: 'HideCTAText',
    textKeyName: 'CTAText',
    windowPreferenceKey: 'CTAWindowPreference',
    windowPreferenceKey2: 'CTALinkWindowPreference',
    type: 'typeOfCtaLink'
  },
  FIRST_CTA: {
    linkKeyName: 'FirstCTALink',
    hideKeyName: 'HideFirstCTA',
    hideKeyName2: 'HideFirstCTAText',
    textKeyName: 'FirstCTAText',
    windowPreferenceKey: 'FirstCTAWindowPreference',
    windowPreferenceKey2: 'FirstCTALinkWindowPreference',
    type: 'typeOfCtaLink'
  },
  SECOND_CTA: {
    linkKeyName: 'SecondCTALink',
    hideKeyName: 'HideSecondCTA',
    hideKeyName2: 'HideSecondCTAText',
    textKeyName: 'SecondCTAText',
    windowPreferenceKey: 'SecondCTAWindowPreference',
    windowPreferenceKey2: 'SecondCTALinkWindowPreference',
    type: 'typeOfCtaLink'
  },
  THIRD_CTA: {
    linkKeyName: 'ThirdCTALink',
    hideKeyName: 'HideThirdCTA',
    hideKeyName2: 'HideThirdCTAText',
    textKeyName: 'ThirdCTAText',
    windowPreferenceKey: 'ThirdCTAWindowPreference',
    windowPreferenceKey2: 'ThirdCTALinkWindowPreference',
    type: 'typeOfCtaLink'
  },
  CALENDER_CTA: {
    linkKeyName: 'CalendarCTALink',
    hideKeyName: 'HideCalendarCTA',
    hideKeyName2: 'HideCalendarCTAText',
    textKeyName: 'CalendarCTAText',
    windowPreferenceKey: 'CalendarCTAWindowPreference',
    windowPreferenceKey2: 'CalendarCTALinkWindowPreference',
    type: 'typeOfCtaLink'
  },
  HIRE_VENUE_CTA: {
    linkKeyName: 'HireVenueCTALink',
    hideKeyName: 'HideHireVenueCTA',
    hideKeyName2: 'HideHireVenueCTAText',
    textKeyName: 'HireVenueCTAText',
    windowPreferenceKey: 'HireVenueCTAWindowPreference',
    windowPreferenceKey2: 'HireVenueCTALinkWindowPreference',
    type: 'typeOfCtaLink'
  },
  VIEW_EVENTS_CTA: {
    linkKeyName: 'ViewEventsCTALink',
    hideKeyName: 'HideViewEventCTA',
    hideKeyName2: 'HideViewEventCTAText',
    textKeyName: 'ViewEventsCTAText',
    windowPreferenceKey: 'ViewEventsCTAWindowPreference',
    windowPreferenceKey2: 'ViewEventsCTALinkWindowPreference',
    type: 'typeOfCtaLink'
  }
}

@Component({
  selector: 'app-page-button',
  templateUrl: './page-button.component.html',
  styleUrls: ['./page-button.component.css']
})
export class PageButtonComponent implements OnInit {

  @Input() ctaType?: CtaType;
  @Input() ctaObj?: any;
  @Input() ctaLink: any = {
    data: '#'
  };
  @Input() ctaWindowPreference: any = {
    data: '[\"_self\"]'
  };
  @Input() typeOfCtaLink: any;
  @Input() hideCta: any = {
    data: 'false'
  };
  @Input() ctaText: any = {
    data: ''
  };
  @Input () anchorClass : string = 'cta-prim color-pink emp-btn';
  @Input () spanClass : string = 'cta-prim-txt';

  ctaKeys: any;
  isShow: boolean = false;
  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
    if(this.ctaObj){
      //console.log('thid');
    }
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.ctaKeys = (this.ctaObj && this.ctaType)? pageButtonTypes[this.ctaType] : undefined;
      this.isShown();
    }, 500)
  }

  onClick(event){
    const link = this.ctaKeys ? this.ctaObj[this.ctaKeys.linkKeyName]?.data : this.ctaLink?.data;
    const windowPreference = this.ctaKeys && this.ctaObj[this.ctaKeys.windowPreferenceKey] ?
    this.ctaObj[this.ctaKeys.windowPreferenceKey]?.data : (this.ctaKeys && this.ctaObj[this.ctaKeys.windowPreferenceKey2]?  this.ctaObj[this.ctaKeys.windowPreferenceKey2]?.data : this.ctaWindowPreference?.data);
    const typeOfCtaLink = this.ctaKeys ? (this.ctaObj[this.ctaKeys.type]? this.ctaObj[this.ctaKeys.type]?.data : '') : (this.typeOfCtaLink?.data || '');
    this._sharedService.checkCTALink(event, link, windowPreference, typeOfCtaLink)
  }

  showText(){
    const text = this.ctaKeys ? this.ctaObj[this.ctaKeys.textKeyName]?.data : this.ctaText?.data;
    return text;
  }

  isShown(){
    if(this.ctaObj && this.ctaKeys){
      const isHide = this.ctaObj && this.ctaObj[this.ctaKeys.hideKeyName] ? this.ctaObj[this.ctaKeys.hideKeyName]?.data
                : (this.ctaKeys && this.ctaObj[this.ctaKeys.hideKeyName2] ? this.ctaObj[this.ctaKeys.hideKeyName2]?.data : this.hideCta?.data);
      this.isShow = isHide === 'false';
      // console.log("##### ctaObj ", JSON.stringify(this.ctaObj), "##### ctakey ", JSON.stringify(this.ctaKeys), " #### show btn - ", this.ctaObj?.ComponentHeaderTitle?.data, '#### isHide ', isHide, '#### isShow ', this.isShow);
    }
  }

  getCtaLink(){
    return this.ctaKeys ? this.ctaObj[this.ctaKeys.linkKeyName]?.data : this.ctaLink?.data;
  }

}

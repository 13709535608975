import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService } from './shared/services/shared.service';
import { StorageService } from './shared/services/storage.service';
import { environment } from './../environments/environment';

declare let gtag: Function;
declare function version(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
  title = 'NMACC_AJ_UI';
  closeNotification = false;
  closeCookieConsent = false;
  browserVersion: boolean;
  islandingPage;
  checkBrowser = false;
  constructor(private router: Router,
    private _sharedService: SharedService,
    private changeDetector: ChangeDetectorRef,
    private storageService: StorageService
  ) {
    this.checkBrowser = (_sharedService.checkBrowser || _sharedService.isProd() || environment.env == 'qa' || environment.env == 'dev')
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    // if (this._sharedService.isProd()) { console.log = () => { } }
    if (this._sharedService.checkBrowser) {
      this.browserVersion = version();
      if (!this.browserVersion) {
        this.router.navigate(['/upgrade-browser']);
      }
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.islandingPage = event.url == '/';
          const preferences = this.getPreferences();
          if (preferences) this.closeCookieConsent = true;
          if (preferences?.analytics) gtag('config', 'G-PKTV8VPBQF', { 'page_path': event.urlAfterRedirects });
          if (this.storageService.getSessionData('token') && event.url !== '/profile') {
            this._sharedService.getJioIdUserDetails().subscribe((res: any) => {
              // console.log(res);
              this.storageService.setSessionData('currUser', JSON.stringify(res.userDetails));
            }, (error) => {
              console.log(error);
              this.storageService.delelteSessionData('token');
              this.storageService.delelteSessionData('currUser');
              this.storageService.delelteSessionData('Wso2Token');
            })

          }
        }
      });
    }
  }

  closeNotificationEvent(event) {
    this.closeNotification = event
  }

  closeCookieConsentEvent(event) {
    this.closeCookieConsent = event
  }

  getPreferences() {
    const match = document.cookie.match(new RegExp('(^| )cookiePreferences=([^;]+)'));
    return match ? JSON.parse(match[2]) : null;
  }
}

export enum OnClickActionsTypeEnum {
  NAVIGATE = 'NAVIGATE',
  DOWNLOAD = 'DOWNLOAD',
  RESPONSE_BACK = 'RESPONSE_BACK',
  DO_NOTHING = 'DO_NOTHING'
}

export enum CtaType {
  CTA = 'CTA',
  FIRST_CTA = 'FIRST_CTA',
  SECOND_CTA = 'SECOND_CTA',
  THIRD_CTA = 'THIRD_CTA',
  CALENDER_CTA = 'CALENDER_CTA',
  HIRE_VENUE_CTA = 'HIRE_VENUE_CTA',
  VIEW_EVENTS_CTA = 'VIEW_EVENTS_CTA'
}

export const DateFormatWhatsOn = 'MMMM  DD, YYYY';

export enum SectionTypes {
  SectionType_Banner = "SectionType_Banner",
  SectionType_ShowTime = "SectionType_ShowTime",
  SectionType_Feature = "SectionType_Feature",
  SectionType_Review = "SectionType_Review",
  SectionType_Share = "SectionType_Share",
  SectionType_Partner = "SectionType_Partner",
  SectionType_CastCreative = "SectionType_CastCreative",
  SectionType_Exhibition = "SectionType_Exhibition",
  SectionType_FullReview = "SectionType_FullReview",
  SectionType_Articles = "SectionType_Articles",
  SectionType_AdditionalData = 'SectionType_AdditionalData',
  SectionType_FirstVIP = 'SectionType_FirstVIP',
  SectionType_SecondVIP = 'SectionType_SecondVIP',
  SectionType_ThirdVIP = 'SectionType_ThirdVIP',
  SectionType_FourthVIP = 'SectionType_FourthVIP',
  SectionType_FifthVIP = 'SectionType_FifthVIP',
  SectionType_SixthVIP = 'SectionType_SixthVIP',
  SectionType_AdditionalDetail='SectionType_AdditionalDetail'
}

export enum PageTypes {
  NMACCHomePage = "NMACCHomePage",
  ShowDetailsPerformingArtsPage= "ShowDetailsPerformingArtsPage",
  ShowDetailsVisualArtsPage= "ShowDetailsVisualArtsPage",
  ShowDetailsCostumeArtsPage= "ShowDetailsCostumeArtsPage",
  VenueListingPage= "VenueListingPage",
  CalendarPage= "CalendarPage",
  ContactUsPage= "ContactUsPage",
  BlogTemplatePage= "BlogTemplatePage",
  AboutUsPage= "AboutUsPage",
  PublicArtsPage= "PublicArtsPage",
  PublicArtsInnerPage= "PublicArtsInnerPage",
  HygienePage= "HygienePage"
  }

  export const VenueTypes = [
    {
      name: 'The Grand Theatre',
      route: 'the_grand_theatre'
    },
    {
      name: 'The Studio Theatre',
      route: 'the_studio_theatre'
    },
    {
      name: 'The Cube',
      route: 'the_cube'
    },
    {
      name: 'Art House',
      route: 'art_house'
    },
    {
      name: 'Pavilion 1',
      route: 'pavilion_1'
    }
]

  export const EventTypes = [
    {
      name: 'Performing Arts',
      route: "performing_arts"
    },
    {
      name: 'Visual Arts',
      route: "visual_arts"
    },
    {
      name: 'Costume Arts',
      route: "costume_arts"
    }
]
  export const BrowseByTypes = [
    { name: 'Acoustic', route: "Acoustic" },
    { name: 'Adaptation', route: "Adaptation" },
    { name: 'Anupam-Kher', route: "Anupam_Kher" },
    { name: 'Apartheid', route: "Apartheid" },
{ name: 'Art-Exhibition', route: "Art_Exhibition" },
{ name: 'Award-winning', route: "Award_winning" },
    { name: 'Band', route: "Band" },
{ name: 'Broadway', route: "Broadway" },
    { name: 'Carnatic-Classical', route: "Carnatic_Classical" },
    { name: 'Carnatic', route: "Carnatic" },
    { name: 'Classical-Fusion', route: "Classical_Fusion" },
    { name: 'Classical-Music', route: "Classical_Music" },
    { name: 'Comedy', route: "Comedy" },
    { name: 'Contemporary', route: "Contemporary" },
{ name: 'Contemporary-Art', route: "Contemporary_Art" },
    { name: 'Contemporary_Dance', route: "Contemporary_Dance" },
    { name: 'Dance', route: "Dance" },
    { name: 'Drama', route: "Drama" },
    { name: 'Electronic', route: "Electronic" },
    { name: 'Family', route: "Family" },
{ name: 'Fashion', route: "Fashion" },
    { name: 'Feroz-Abbas-Khan', route: "Feroz_Abbas_Khan" },
    { name: 'Folk-Music', route: "Folk_Music" },
    { name: 'Gandhi', route: "Gandhi" },
{ name: 'Gujarati', route: "Gujarati" },
    { name: 'Gujarati-Folk', route: "Gujarati_Folk" },
    { name: 'Hindustani-Classical', route: "Hindustani_Classical" },
    { name: 'India-Inspired', route: "India_Inspired" },
{ name: 'Indian-Classical-Fusion', route: "Indian_Classical_Fusion" },
    { name: 'Indian-Dance', route: "Indian_Dance" },
    { name: 'Indian-Music', route: "Indian_Music" },
{ name: 'Indian-Textiles', route: "Indian_Textiles" },
    { name: 'Indie', route: "Indie" },
    { name: 'Instrumental', route: "Instrumental" },
    { name: 'Kaifi-Azmi', route: "Kaifi_Azmi" },
    { name: 'Mahatma-Gandhi', route: "Mahatma_Gandhi" },
    { name: 'Mahesh-Elkunchwar', route: "Mahesh_Elkunchwar" },
    { name: 'Mame-Khan', route: "Mame_Khan" },
    { name: 'Marathi', route: "Marathi" },
    { name: 'Music', route: "Music" },
    { name: 'Musical', route: "Musical" },
    { name: 'Percussion', route: "Percussion" },
    { name: 'Play', route: "Play" },
    { name: 'Play-Reading', route: "Play_Reading" },
    { name: 'Poetry', route: "Poetry" },
{ name: 'Qawwali', route: "Qawwali" },
    { name: 'Rajasthan', route: "Rajasthan" },
    { name: 'Regional-Music', route: "Regional_Music" },
    { name: 'Regional-Theatre', route: "Regional_Theatre" },
    { name: 'Solo-Performance', route: "Solo_Performance" },
    { name: 'Sufi-Songs', route: "Sufi_Songs" },
    { name: 'Theatre', route: "Theatre" },
    { name: 'Traditional', route: "Traditional" },
    { name: 'Urdu-Poetry', route: "Urdu_Poetry" }
]


  export const BannerSectionType = {
    kma: '["kma"]',
    sliding: '["sliding_banner"]',
    kma_New: 'kma',
    sliding_New: 'sliding_banner'
  }

export const CategoryType={
  '["Public Arts"]':'Public Arts',
  '["Visual Arts"]':'Visual Arts',
  '["Venues"]':'Venues',
  '["Category One"]':'Category One',
  '["Category Two"]':'Category One',
}
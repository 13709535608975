import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['./faq-section.component.css']
})
export class FaqSectionComponent implements OnInit {
  @Input() faqData;
  @Input() colorList;
  @Input() sectionClass?;
  colorClass: Map<string, string>;
  constructor(public _sharedService: SharedService, private router: Router, private elementRef: ElementRef, private titleService: Title) {
    this.colorClass = new Map([
      ["#1E478E", "color-blue"],
      ["#C92C99", "color-pink"],
      ["#43B02A", "color-green"],
      ["#A67D54", "color-gold"]
    ]);
  }

  ngOnInit() {
    //console.log(this.faqData);
  }

  // ngAfterViewInit() {
  //   if (this.elementRef.nativeElement.querySelector('.custom-anchor')) {
  //     this.elementRef.nativeElement.querySelector('.custom-anchor').addEventListener('click', this.goToTC.bind(this));
  //   }
  // }

  // goToTC() {
  //   this.router.navigate(['/legal'], {
  //     state: { tabName: 'terms' }
  //   });
  //   return false;
  // }

  callFaqSectionCTEvent(type, staticLink?) {
    switch(type){
      case 'sec':
      this._sharedService.callCTEvent('Section_Secondary_Links', this.faqData.ComponentHeaderTitle.data);
      break;
      case 'cont':
      this._sharedService.callCTEvent('Section_Content_Links', this.faqData.ComponentHeaderTitle.data, '', staticLink);
      break;  
    }
    
  }
}

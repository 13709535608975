import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-back-to-wrapper',
  templateUrl: './back-to-wrapper.component.html',
  styleUrls: ['./back-to-wrapper.component.css']
})
export class BackToWrapperComponent implements OnInit {

  @Input() isHomePage: boolean = false;

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
  }
  callCTBackToHomeEvent(name){
    this._sharedService.callCTEvent('Sticky_Menu_Links', '', '', name);
  }

}

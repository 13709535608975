import { Component, Input, OnInit } from '@angular/core';
import { CtaType } from '../../config/enum.config';


const defaultConfig = {
  type : 'jwc_swiper_normal',
  outerClass: 'feature_shows_slider jwc_swiper_normal',
  showPagination: false,
  ctaOne: {
    type: CtaType.FIRST_CTA,
    anchorClass: 'cta-sec color-pink'
  }
}
@Component({
  selector: 'app-slider-data',
  templateUrl: './slider-data.component.html',
  styleUrls: ['./slider-data.component.css']
})
export class SliderDataComponent implements OnInit {

  ctaType = CtaType;
  _sliderConfig: {
    type : string,
    outerClass: string,
    showPagination: boolean,
    ctaOne: {
      type: CtaType,
      anchorClass: string
    }
  };
  @Input() sectionData: any;
  @Input() set sliderConfig(value) {
    this._sliderConfig = { ...defaultConfig, ...value}
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})
export class CookiePolicyComponent implements OnInit {
  @Output() closeCookieConsent = new EventEmitter();
  constructor(public _sharedService: SharedService, private cookieService: CookieService) { }

  ngOnInit() { }

  hideCookieConsent() {
    this.closeCookieConsent.emit('true');
    if (typeof window !== 'undefined') {
      localStorage.setItem('cookieConsent', 'true');
    }
  }

  preferences = {
    necessary: true,
    analytics: false,
    marketing: false
  };

  acceptAll() {
    this.cookieService.setPreferences({
      necessary: true,
      analytics: true,
      marketing: true
    });
  }

  rejectAll() {
    this.cookieService.setPreferences({
      necessary: true,
      analytics: false,
      marketing: false
    });
  }


  savePreferences() {
    this.cookieService.setPreferences(this.preferences);
  }

}

import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BackToWrapperComponent } from "./components/back-to-wrapper/back-to-wrapper.component";
import { BannerNewComponent } from "./components/banner-new/banner-new.component";
import { BannerComponent } from "./components/banner/banner.component";
import { CookiePolicyComponent } from "./components/cookie-policy/cookie-policy.component";
import { Covid2SectionComponent } from "./components/covid2-section/covid2-section.component";
import { P404Component } from "./components/error/404.component";
import { P500Component } from "./components/error/500.component";
import { FaqSectionComponent } from "./components/faq-section/faq-section.component";
import { SubscribeSectionComponent } from "./components/subscribe-section/subscribe-section.component";
import { Subscribe2Component } from "./components/subscribe2-section/subscribe2-section.component";
import { VideoPopupComponent } from "./components/video-popup/video-popup.component";
import { WebsiteNotificationComponent } from "./components/website-notification/website-notification.component";
import { SafePipe } from "./pipes/safe.pipe";
import { PagePictureComponent } from './components/page-picture/page-picture.component';
import { PageButtonComponent } from './components/page-button/page-button.component';
import { FetchKeyPipe } from './pipes/fetch-key.pipe';
import { HeaderTitlePipe } from './pipes/header-title.pipe';
import { HeaderSubTitlePipe } from './pipes/header-sub-title.pipe';
import { EventTitlePipe } from './pipes/event-title.pipe';
import { EventDescPipe } from './pipes/event-desc.pipe';
import { EventImagePipe } from './pipes/event-image.pipe';
import { TheatresComponent } from './components/theatres/theatres.component';
import { FaqComponent } from './components/faq/faq.component';
import { CheckSectionShowPipe } from './pipes/check-section-show.pipe';
import { EventDatePipe } from './pipes/event-date.pipe';
import { EventTimePipe } from './pipes/event-time.pipe';
import { EventVenuePipe } from './pipes/event-venue.pipe';
import { SliderDataComponent } from './components/slider-data/slider-data.component';
import { SubscribeRibbonComponent } from './components/subscribe-ribbon/subscribe-ribbon.component';
import { WhatsOnComponent } from './components/whats-on/whats-on.component';
import { VenueDetailsShowcaseComponent } from "./components/venue-details-showcase/venue-details-showcase.component";
import { InquiryThankYouComponent } from "./components/inquiry-home/inquiry-thank-you/inquiry-thank-you.component";
import { AllowedInputDirective } from './directives/allowed-input.directive';
import { RecaptchaModule } from "ng-recaptcha";
import { ThankYouPageComponent } from "./components/thank-you-page/thank-you-page.component";
import { Instagram2SectionComponent } from "./components/instagram2-section/instagram2-section.component";
import { ReadComponent } from './components/read/read.component';
import { BannerWithThumbailComponent } from './components/banner-with-thumbail/banner-with-thumbail.component';
import { BannerSlidingComponent } from './components/banner-sliding/banner-sliding.component';
import { ReadSlidingComponent } from './components/read-sliding/read-sliding.component';
import { SpacesComponent } from './components/spaces/spaces.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';

import { VjsVideoComponent } from './components/vjs-video/vjs-video.component';
import { PlanBigEventComponent } from "./components/plan-big-event/plan-big-event.component";
import { YouMayLikeComponent } from './components/you-may-like/you-may-like.component';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { MapComponent } from "./components/map/map.component";
import { WhatsOnCardComponent } from './components/whats-on-card/whats-on-card.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { NoOfDataToShowPipe } from './pipes/no-of-data-to-show.pipe';
import { FeaturedShowsComponent } from './components/featured-shows/featured-shows.component';
import { OtpVerifyComponent } from './components/otp-verify/otp-verify.component';
import { NgOtpInputModule } from "ng-otp-input";
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { FonPresalesComponent } from './components/fon-presales/fon-presales.component';
import { BenefitsWrapperComponent } from "./components/benefits-wrapper/benefits-wrapper.component";
import { VipCardsComponent } from './components/vip-cards/vip-cards.component';

@NgModule({
    declarations: [
      WebsiteNotificationComponent,
      CookiePolicyComponent,
      Covid2SectionComponent,
      SubscribeSectionComponent,
      VideoPopupComponent,
      SafePipe,
      InquiryThankYouComponent,
      ThankYouPageComponent,
      AllowedInputDirective,
      BackToWrapperComponent,
      BannerNewComponent,
      BannerComponent,
      P404Component,
      P500Component,
      FaqSectionComponent,
      Subscribe2Component,
      Instagram2SectionComponent,
      PagePictureComponent,
      PageButtonComponent,
      FetchKeyPipe,
      HeaderTitlePipe,
      HeaderSubTitlePipe,
      EventTitlePipe,
      EventDescPipe,
      EventImagePipe,
      TheatresComponent,
      FaqComponent,
      CheckSectionShowPipe,
      EventDatePipe,
      EventTimePipe,
      EventVenuePipe,
      SliderDataComponent,
      SubscribeRibbonComponent,
      WhatsOnComponent,
      VenueDetailsShowcaseComponent,
      AllowedInputDirective,
      ReadComponent,
      BannerWithThumbailComponent,
      BannerSlidingComponent,
      ReadSlidingComponent,
      SpacesComponent,
      LoginDialogComponent,
      VjsVideoComponent,
      PlanBigEventComponent,
      YouMayLikeComponent,
      CommonPopupComponent,
      MapComponent,
      WhatsOnCardComponent,
      NoOfDataToShowPipe,
      FeaturedShowsComponent,
      OtpVerifyComponent,
      ComingSoonComponent,
      FonPresalesComponent,
      BenefitsWrapperComponent,
      VipCardsComponent
    ],
    imports:[
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        MatTooltipModule,
        NgOtpInputModule
    ],
    exports: [
      WebsiteNotificationComponent,
      CookiePolicyComponent,
      Covid2SectionComponent,
      SubscribeSectionComponent,
      SafePipe,
      InquiryThankYouComponent,
      ThankYouPageComponent,
      AllowedInputDirective,
      BackToWrapperComponent,
      VideoPopupComponent,
      BannerNewComponent,
      BannerComponent,
      P404Component,
      P500Component,
      FaqSectionComponent,
      Subscribe2Component,
      Instagram2SectionComponent,
      PagePictureComponent,
      PageButtonComponent,
      HeaderTitlePipe,
      HeaderSubTitlePipe,
      FetchKeyPipe,
      EventTitlePipe,
      EventDescPipe,
      EventImagePipe,
      TheatresComponent,
      FaqComponent,
      CheckSectionShowPipe,
      EventDatePipe,
      EventTimePipe,
      EventVenuePipe,
      SliderDataComponent,
      SubscribeRibbonComponent,
      WhatsOnComponent,
      ReactiveFormsModule,
      VenueDetailsShowcaseComponent,
      ReadComponent,
      BannerWithThumbailComponent,
      BannerSlidingComponent,
      ReadSlidingComponent,
      SpacesComponent,
      VjsVideoComponent,
      PlanBigEventComponent,
      YouMayLikeComponent,
      CommonPopupComponent,
      MapComponent,
      WhatsOnCardComponent,
      FeaturedShowsComponent,
      OtpVerifyComponent,
      FonPresalesComponent,
      BenefitsWrapperComponent,
      VipCardsComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class SharedModule {}
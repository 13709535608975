import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../shared/services/storage.service';
import { SharedService } from '../../../../shared/services/shared.service';
declare function loader(): any;
declare let fbq: Function;
// declare function gtag(a, b, c): any;

@Component({
  selector: 'app-inquiry-thank-you',
  templateUrl: './inquiry-thank-you.component.html',
  styleUrls: ['./inquiry-thank-you.component.css']
})
export class InquiryThankYouComponent implements OnInit {
  inquiryColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor: "#976841"
  };
  inqId = 'xxxxxxxx';
  previousPage: string;
  thankYouData = [
    {
      heading: "Thank you for submitting your enquiry.",
      previousPage: "contact-us"
    },
    {
      heading: "Thank you for writing to us! We'll get back to you at the very earliest. Have a lovely day",
      previousPage: "enquiry"
    },
    {
      heading: "Thank you for submitting your enquiry for dining.",
      previousPage: "dining"
    },
    {
      heading: "Thank you for submitting your collaboration for NMACC.",
      previousPage: "collab-form"
    },
  ]
  constructor(private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService) {
    this.route.paramMap.subscribe((data: any) => {
      this.previousPage = data?.params?.type;
    })

    const inqId = this.storageService?.getSessionData('inqId');
    if (inqId) {
      this.inqId = inqId;
      this.storageService?.delelteSessionData('inqId');
    } else {
      this.router?.navigateByUrl('/');
    }
  }

  ngOnInit() {
    // gtag('event', 'conversion', {'send_to': 'AW-662181025/FI8WCNyO1MgBEKGp4LsC'});
    setTimeout(function () {
      loader();
      fbq('track', 'Lead');
    }, 250);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SharedService } from '../services/shared.service';

@Pipe({
  name: 'eventImage'
})
export class EventImagePipe implements PipeTransform {

  staticUrlForImgNew: any;
  
  constructor(private _sharedService: SharedService){
    //this.staticUrlForImgNew = this._sharedService.getBaseUrl();
    this.staticUrlForImgNew = environment.staticUrlForImgNew;
  }

  transform(value: any, type = 'WEB', key = ''): unknown {
    if (key != '') {
      return value && value[key] && value[key].image ? `${this.staticUrlForImgNew}${value[key].image.contentUrl}` : '';
    }
    switch (type) {
      case 'WEB' :
        return value && value.CardImage && value.CardImage.image ? `${this.staticUrlForImgNew}${value.CardImage.image.contentUrl}` : '';
      case 'MOBILE':
        return value && value.MobileCardImage && value.MobileCardImage.image ? `${this.staticUrlForImgNew}${value.MobileCardImage.image.contentUrl}` : '';  
    }
    
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @Input() accessWithEaseData;
  @Input() colorList;
  @Input() sectionClass?;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  colorClass: Map<string, string>;
  pageName;
  locationData;


  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private titleService: Title) {
    this.colorClass = new Map([
      ["#43B02A", " color-green"],
      ["#1E478E", "color-blue"],
      ["#C69E67", "color-gold"]
    ]);
   }

  ngOnInit() {
    this.pageName = this.activatedRoute.snapshot.data['page'];
    this.locationData = 'Nita Mukesh Ambani Cultural Centre'
    // (this.pageName == 'home')?'Jio World Centre': (this.pageName == 'cec')?
    //  'Jio World Convention Centre':(this.pageName == 'garden')?'Jio World Garden' : 'Jio World Centre';
    this._sharedService.callGoogleMaps();
  }
  callCTGetDirectionsEvent() {
    this._sharedService.callCTEvent('Get_Directions_Links', this.accessWithEaseData.ComponentHeaderTitle.data, '', this.accessWithEaseData.GetDirectionsCTAText.data);
  }

}

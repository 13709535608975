import { Component, ElementRef, Inject, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { SharedService } from '../../services/shared.service';
import { BrowseByTypes, DateFormatWhatsOn, EventTypes, VenueTypes } from '../../config/enum.config';
import { ActivatedRoute, Router } from '@angular/router';
import { OpeningShowsDataDesc } from '../../config/interface.config';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { StorageService } from '../../services/storage.service';
import {  map } from 'rxjs/operators';
import { Location } from '@angular/common';

declare var jQuery: any;

const defaultFilterValue = 'ALL';
const defaultEventCategory = 'General';

const defaultSelector = {
  eventType: {
    isOpen: false,
    value: defaultFilterValue,
    data: EventTypes
  },
  venueType: {
    isOpen: false,
    value: defaultFilterValue,
    data: VenueTypes
  },
  browseBy: {
    isOpen: false,
    value: 'Browse By',
    data: BrowseByTypes
  },
  dateTime: {
    isOpen: false,
    value: 'DATE & TIME',
  },
}
@Component({
  selector: 'app-whats-on',
  templateUrl: './whats-on.component.html',
  styleUrls: ['./whats-on.component.css']
})
export class WhatsOnComponent implements OnInit, OnChanges {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  searchForm: any;
  originalData: any = [];
  filterdData;
  openingShowLength;
  eventTypes = [];
  venueTypes = [];
  browseByTypes = [];
  selectedTag: Array<any> = [];
  selectedVenue: string = '';
  selectedEvent: string = '';
  jArr = ['#searchDate', '#searchTime'];
  selectedBrowseByValues = [];
  isCalendarPage;
  searchFlag = false;

  classObject={
    outerDiv:'whats_on_categories_wrapper_item',
    contentDiv_1:'copy-container',
    contentDiv_2:'whats_on_categories_wrapper_item_copy content3',
    subtitleAnchor:'show_name',
    subtitleHeading:'mnhieght',
    largeTitlePara:'show_descr',
    ctaAnchor:'w_189',
  }

  openingShowsConfig = {
    showImg: true,
    showTitle: true,
    showSubTitle: true,
    showLargeTitleCard: true,
    showDate: true,
    showVenue: true,
    showPrice: true,
    showRemainingSeat: true,
    showFirstCTA: true,
    showSecondCTA: true,
    showBookNowCTA: true,
  };

  @Input('showCount') showCount;
  @Input() set whatsonData(value) {
    
    if(Array.isArray(value)) {
      const shows = [];
      value.forEach((item, itemIndex) => {
        let items = item?.AddCardDetails?.filter((item) => item.HideEventOnSite !== true);
        if (this.pageName) {
          items = items?.filter((item) => item[this.pageName] === true);
        }
        item.AddCardDetails = items;
        shows.push(item);
      })
      this.originalData = shows;
      // console.log(this.filterdData);
     
      this.getSearchData('set whatsonData');

    }
      
  }

  @Input() openingShowsDataDesc: OpeningShowsDataDesc;
  @Input() pageName: string;
  @Input() showWeekDiv = false;
  @Input() showEventCategory: string = defaultEventCategory;

  filterTypes = JSON.parse(JSON.stringify(defaultSelector));
  defualtFilterTypes = defaultSelector;
  queryObj : any = {};
  @ViewChild('openingShows') openingShowsDiv: ElementRef;

  constructor(private fb: FormBuilder, 
    public _sharedService: SharedService,
    private route: ActivatedRoute,
    private router:Router,
    public dialog:MatDialog,
    private storageService: StorageService,
    @Inject(Location) private location: any) { 
      this.searchForm = this.fb.group({
        searchDate: ['Date'],
        searchTime: ['00:00 AM'],
        eventType: [defaultSelector.eventType.value],
        venueType: [defaultSelector.venueType.value],
        browseBy: [-1],
        searchText:''
      })
    }

  ngOnChanges(){
   if(this.openingShowsDataDesc?.CardsDisplayCount?.data){
    this.showCount = this.getShowCount(this.openingShowsDataDesc?.CardsDisplayCount?.data)
   }
  }

  getShowCount(input) {
    let count = 0;
    if(input) count = input.match(/(\d+)/);
    return +count?.[0];
  }

  loadMore(){
    this.showCount += this.getShowCount(this.openingShowsDataDesc?.CardsDisplayCount?.data)
  }

  ngOnInit(): void {
    const eventVenueBrowseByKeys = [
      'Event_Types',
      'Event_Venues',
      'Event_Tags'
    ]
    this._sharedService.getCommonService().getlandingUrl(eventVenueBrowseByKeys)
    .pipe(
      map((items: any) => {
        return items.map((item:any) => item?.data)
      })
    )
    .subscribe((res) => {
      this.route.queryParams.subscribe((params) => {
        this.processQueryParams(params);
        const eventTypes = this.showEventCategory && this.showEventCategory !== defaultEventCategory ? res?.[0]?.EventTypesResponse.filter((item) => item.category === this.showEventCategory) : res?.[0]?.EventTypesResponse;
        this.eventTypes = eventTypes;
        this.venueTypes = res?.[1]?.EventVenuesResponse;
        this.browseByTypes = res?.[2]?.EventTagsResponse;
        defaultSelector.eventType.data = eventTypes;
        defaultSelector.venueType.data = res?.[1]?.EventVenuesResponse;
        defaultSelector.browseBy.data = res?.[2]?.EventTagsResponse;
        this._sharedService.callGardenWhatsOnFilter()
        this.getSearchData('setControlAndSearch');

      })
    }, (err) => {
      console.log(err);
    })
    this.jArr.forEach(item => {
      this.dynamicjQueryFn(item);
    });
    this.isCalendarPage=this.router.url.includes('calendar')
  }

  processQueryParams(res) {
    if(res.tag) {
      this.selectedTag= res.tag.toLowerCase().split(',');
      this.filterTypes.browseBy.isOpen =  true;
      this.selectedBrowseByValues = [] // comment - to avoid removing of tag if present already
      this.selectedTag.forEach((item) => this.setBrowseByType(item));
      this.queryObj.tag = this.selectedBrowseByValues.join(',');
    }
    if(res.venue) {
      this.selectedVenue = res.venue.toLowerCase();
      this.setControlAndSearch('venueType', this.selectedVenue);
      this.queryObj.venue = this.selectedVenue;
    }
    if(res.event) {
      this.selectedEvent = res.event.toLowerCase();
      this.setControlAndSearch('eventType', this.selectedEvent); 
      this.queryObj.event = this.selectedEvent;
    }
    if(res.eventCategory) {
      this.showEventCategory = res.eventCategory;
    }
    if(res.date) {
      const date = res.date.toLowerCase();
      this.setControlAndSearch('searchDate', date); 
      this.queryObj.date = date;
    }
  }

  setDropDown(controlName, value) {
    const oldValue = this.searchForm.get(controlName).value;
    this.setControlAndSearch(controlName, value);
    if(value !== oldValue) {
      this.pageRedirect();
    }
  }

  setDropdownTag(value) {
    this.setBrowseByType(value);
    this.pageRedirect();
  }

  setControlAndSearch(controlName, value) {
    // console.log(`setControlSearch`, controlName, value)
    const oldValue = this.searchForm.get(controlName).value;
    this.queryObj[this.getKeyName(controlName)]= value.toLowerCase();
    // if(value === oldValue || value.toLowerCase() == defaultFilterValue.toLowerCase()) return;
    // this.searchForm.get(controlName).setValue(value);
    if(value === oldValue) return; // comment - allow to select defaultFilterValue of dropdown
    this.searchForm.get(controlName).setValue(value == 'all' ? 'ALL' : value);
  }

  pageRedirect() {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // };
    if(this.selectedBrowseByValues.length > 0 ) {
      this.queryObj.tag = this.selectedBrowseByValues.join(',');
    } else {
      delete this.queryObj.tag;
    }
    // if(this.showEventCategory) {
    //   this.queryObj.eventCategory = this.showEventCategory;
    // }
    delete this.queryObj.time; // time is not be shown in url as of now
    this.router.navigate([this.currentUrl], {
      queryParams: this.queryObj,
      relativeTo: this.route,
      fragment: 'NMACC-OPENING-SHOWS' // comment - to scroll into view
    })
  }

  get currentUrl() {
    return this.location._platformLocation.location.pathname;
  }

  getKeyName(controlName) {
    switch (controlName) {
      case 'eventType': 
        return 'event';
      case 'venueType': 
        return 'venue'; 
      case 'searchDate': 
        return 'date';  
      case 'searchTime': 
        return 'time';
      default: 
        return 'event';
    }
  }

  setBrowseByType(value) {
    const findIndex = this.selectedBrowseByValues.findIndex((item) => item === value);
    if(findIndex > -1) {
      this.selectedBrowseByValues.splice(findIndex, 1);
    } else {
      this.selectedBrowseByValues.push(value);
    }
  }

  getSearchData(source) {
    // console.log(`source: `, source);
    if(this.originalData.length === 0) return;
    this.filterdData = JSON.parse(JSON.stringify(this.originalData));
    let searchEventType: string | number = this.getSearchableValue('eventType');
    let searchVenueType: string | number = this.getSearchableValue('venueType');
    let searchDate = this.getFormControlValue('searchDate') !== 'Date' ? this.getFormControlValue('searchDate') : 'Date';
    let searchTime =this.getFormControlValue('searchDate') !== 'Date' ? this.getFormControlValue('searchTime') : '';

    const shows = [];
    this.filterdData.forEach((item, itemIndex) => {
      let items = item?.AddCardDetails;
      if(searchEventType!== defaultSelector.eventType.value) {
        this.searchFlag = true;
        items = items?.filter((item) => item.EventType?.route?.toLowerCase() === searchEventType.toString().toLowerCase());
      }
      if(searchVenueType!== defaultSelector.venueType.value) {
        this.searchFlag = true;
        items = items.filter((item) => item.Venue?.route?.toLowerCase() === searchVenueType.toString().toLowerCase()); 
      }
      if(this.selectedBrowseByValues.length > 0){
        this.searchFlag = true;
        items = items.filter((item) => {
          let flag = false;
          this.selectedBrowseByValues.forEach((i) => {
            if(item.SelectedTags?.find((tagObj) => tagObj.route?.toLowerCase() === i.toLowerCase())) {
              flag = true;
            }
          })
          return flag;
        });
      }
      if(searchDate!== 'Date') {
        this.searchFlag = true;
        items = items.filter((item) => {
          // searchTime = '12:00 AM';
          const date = moment.utc(searchDate, 'DD/MM/YY').format('YYYY-MM-DD');
          const fromTime = new Date(date+' '+searchTime).getTime();
          //const toTime = new Date(date+' '+'11:59 PM').getTime();
          const publishTime = new Date(item.PublishDate.slice(0,10)+' '+'00:00 AM').getTime();
          const expirationTime = item?.ExpirationDate ? item?.ExpirationDate : item.PublishDate;
          const exTime = new Date(expirationTime.slice(0,10)+' '+'11:59 AM').getTime();
          //if(publishTime >= fromTime && publishTime <= toTime)
          if(fromTime >= publishTime && fromTime <= exTime) {
            // console.log(item);
            return true;
          }
            
        }); 
      }
      let searchText = this.getFormControlValue('searchText')
      if(searchText) {
        this.searchFlag = true;
        items = this.filterByKeys(items, searchText)
      }
      if(this.showEventCategory != defaultEventCategory) {
        //this.searchFlag = true;
        items = items.filter((res) => res?.EventType?.category === this.showEventCategory);
      }
      if(items?.length > 0) {
        item.AddCardDetails = items;
        shows.push(item);
      } 
    });
    if(this.searchFlag || this.showEventCategory!=defaultEventCategory) {
      this.filterdData = shows;
    }
    // if(this.searchFlag) this.openingShowsDiv.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" }); // comment - to avoid scrolling again and again
    this.openingShowLength= this.filterdData?.reduce((acc, curr) => acc?.concat(curr?.AddCardDetails), [])?.length;
  }

  filterByKeys(array, searchText){
    array = array.filter((item) => {
      // return JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())

      return item?.SubTitle?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.LargeTitleCard?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.Venue?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.EventType?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.SelectedTags.some((tag)=>tag?.name?.toLowerCase().includes(searchText.toLowerCase())) 
    }); 
    return array
  }

  getFormControlValue(controlName) {
    return this.searchForm.get(controlName).value;
  }

  getSearchEventOrVenue(bucket, item) {
    const selectedType = bucket.find((item) => item.route.toLowerCase() === item);
    return selectedType;
  }

  setFilterTypes(controlName, value) {
    // console.log(controlName, value)
    value = (value === -1 || value === defaultSelector[controlName].value) ? defaultSelector[controlName].value : defaultSelector[controlName].data.find((item) => item.route.toLowerCase() === value.toLowerCase())?.name;
    // console.log(value);
    this.filterTypes[controlName].value = value;
  }

  getSearchableValue(controlName) {
    let value: string | number = defaultSelector[controlName].value;
    if(this.getFormControlValue(controlName) !== defaultSelector[controlName].value) {
      value = this.getFormControlValue(controlName);
    }
    this.setFilterTypes(controlName, value);
    return value;
  }

  dynamicjQueryFn(id) {
    if (this._sharedService.checkBrowser) {
      jQuery('body').on('change', id, function () {
        jQuery(id).trigger('click');
      });
    }
  }

    
  getDateFormat(date: any , format = 'dd/mm/yyyy') {
    return moment.utc(date).format(format);
  }

  generateSlug(value){
    return value.split(' ').join('-').toLowerCase();
  }

  getTimeFormat(time, format = 'H:mm:ss'){
    const date = moment.utc().format('YYYY-MM-DD');
    return moment.utc(date +' '+time).format(format);
  }

  isTagActive(tag) {
    return this.selectedBrowseByValues.find((item) => item === tag || item?.toLowerCase() === tag?.toLowerCase());
  }

  get pageHeading(){
    const broseByNames = [];
    this.browseByTypes?.forEach((item) => {
      for (const ele of this.selectedBrowseByValues) {
        if(ele === item.route) {
          broseByNames.push(item.name);
          break;
        }
      }
    })
    return this.selectedBrowseByValues.length > 0
                        ? `${broseByNames.join(', ')} Event(s)`
                        : this.openingShowsDataDesc?.ComponentHeaderTitle?.data;

  }

  resetFilter(){
    this.selectedBrowseByValues = [];
    this.filterTypes = JSON.parse(JSON.stringify(defaultSelector));
    this.initilizeForm();
    this.filterdData = this.originalData;
    this.selectedTag = [];
    this.queryObj = {};
    this.pageRedirect();
    this.searchFlag = false
  }

  initilizeForm() {
    this.searchForm.patchValue({
      searchDate: 'Date',
      searchTime: '00:00 AM',
      eventType: defaultSelector.eventType.value,
      venueType: defaultSelector.venueType.value,
      browseBy: -1,
      searchText:''
    });
  }

  get searchFormControls() {
    return this.searchForm.controls;
  }

  get isFormHavingDefaultValue() {
    return this.searchFormControls['searchDate'].value === 'Date'
            && this.searchFormControls['searchTime'].value === '00:00 AM'
            && this.searchFormControls['eventType'].value === defaultSelector.eventType
            && this.searchFormControls['venueType'].value === defaultSelector.venueType
            && this.searchFormControls['browseBy'].value === -1
  }

  findActiveControl(type, value){
    return this.searchFormControls[type].value === value ? 'active': '';
  }

  findOpenControl(type){
    return this.filterTypes[type].isOpen ? 'open' : '';
  }

  setFilterOpenStatus(type) {
    Object.keys(this.filterTypes).forEach((key) => {
      if(key !== type) this.filterTypes[key].isOpen = false;
    })
    this.filterTypes[type].isOpen = !this.filterTypes[type].isOpen
  }

  getControlValue(type) {
    //if(type === 'eventType') console.log(this.filterTypes.eventType);
    return this.filterTypes[type]?.value?.length>12 ? `${this.filterTypes[type]?.value?.substr(0,13)}...` : this.filterTypes[type]?.value;
  }

  getShowTimes(item){
    return item.PublishTime ? ` | ${item.PublishTime}` : '';
  }

  getDateRange(item) {
    let string = '';
    if(!!!item?.PublishDate) return '';
    if(!!item?.ExpirationDate && item?.PublishDate != item?.ExpirationDate) {
    }
    string += this.getDateFormat(item?.PublishDate, DateFormatWhatsOn);
    if(!!item.ExpirationDate && item.PublishDate != item.ExpirationDate)
      string += ` to ${this.getDateFormat(item.ExpirationDate, DateFormatWhatsOn)}`;
    return string;
  }

  getShortText(value, lenthToShow = 80){
    return value?.length > lenthToShow ? `${value.substr(0,lenthToShow)}..` : value;
  }

  openDialog(item): void {
    this.storageService.setSessionData('currShow', item?.EventID);
    const token = this.storageService.getSessionData('token');
    if(token) {
      const currShow = this.storageService.getSessionData('currShow');
      const currUser = JSON.parse(this.storageService.getSessionData('currUser'));
      const payload = {
        showId: currShow,
        token,
        name: currUser?.commonName,
        mobile: currUser?.defaultMobile,
        email: currUser?.defaultMail
      }
      this._sharedService.openMicrositeUrl(payload);
    } else {
      this.dialog.open(LoginDialogComponent, {
        width: '700px',
      });
    }
  }

  guest(item){
    this._sharedService.openBookNowAsGuest(item?.EventID);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventDate'
})
export class EventDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.EventDate ? value.EventDate.data : (value && value.Date ? value.Date.data : '');
  }

}

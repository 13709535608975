import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerTitle'
})
export class HeaderTitlePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.ComponentHeaderTitle ? value.ComponentHeaderTitle.data : (value && value.HeaderTitle && value.HeaderTitle.data ? value.HeaderTitle.data : (value && value.Text1x0x && value.Text1x0x.data ? value.Text1x0x.data : (value && value.ComponentTitle && value.ComponentTitle.data ? value.ComponentTitle.data : '')));
  }

}

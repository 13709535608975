import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })

export class AppInitService {
  checkBrowser: boolean = false;
  flag = true;
  constructor(private httpClient: HttpClient, @Inject(Location) private location: any) {}

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }

  loadUrls(): Promise<any> {
    const platFormLocation = this.location._platformLocation;
    const port = platFormLocation.port && platFormLocation.port != '' ? ':' + platFormLocation.port : '';
    const baseHref = `${platFormLocation.protocol}//${platFormLocation.hostname}${port}/`;
    // if(this.flag) {
    //   console.log(platFormLocation);
    //   console.log(baseHref);
    //   this.flag = false;
    // }

    const promise = this.httpClient
    .get( baseHref + "/assets/config/environment.json")
    // .get( (baseHref.includes('webcache') ? 'https://nmacc.com/' : baseHref) + "/assets/config/environment.json")
    .toPromise()
    .then((env: any) => {
      for(let key in env) {
        environment[key] = env[key];
      };
        // console.log({jsonEnv: environment});
      return env;
    });
    return promise;
    
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.css']
})
export class VideoPopupComponent implements OnInit {
  constructor(public _sharedService: SharedService) { 
  }

  ngOnInit() {   
    this._sharedService.callVideoPopup();
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-featured-shows',
  templateUrl: './featured-shows.component.html',
  styleUrls: ['./featured-shows.component.css']
})
export class FeaturedShowsComponent implements OnInit {
  openingShowData: any;
  openingShowLength = 0;
  @Input() featuredShowsData;
  @Input() openingShowsConfig;
  @Input() classObject;
  @Input('openingShowData') set setShowData(res) {
    if (Array.isArray(res) && res.length > 0) {
      this.openingShowData = [...res];
      this.openingShowLength = res?.reduce((acc, curr) => acc?.concat(curr?.AddCardDetails), [])?.length;
    }
  };

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void { }

}

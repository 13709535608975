import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fetchKey'
})
export class FetchKeyPipe implements PipeTransform {

  transform(value: any, ...args: string[]): string {
    return Object.keys(value).length > 0 ?
      args.length > 1 ? value[args[0]][args[1]] : ( args.length > 0 ?  value[args[0]] : 'No Key found.')
      : '';
  }

}

import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
//import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class StorageService {
  userDetails: Subject<any> = new BehaviorSubject<any>(null);
  checkBrowser: any;
  constructor(
    @Inject(PLATFORM_ID) platformId: string
    // private cookieService: CookieService
  ) { 
    this.checkBrowser = isPlatformBrowser(platformId);
  }

  setSessionData(key: string, value: any) {
    if(this.checkBrowser) {
      localStorage.setItem(key, JSON.stringify(value));
      this.userDetails.next(true);
    }
     
  }

  getSessionData(key: string) {
    if(this.checkBrowser) {
      let value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      } else {
        return null;
      }
    }
  }

  clearSessionData() {
    if(this.checkBrowser) {
      localStorage.clear();
    }
  }

  delelteSessionData (item) {
    if(this.checkBrowser) {
      localStorage.removeItem(item);
    }
  }

  getUserCookie() {
    // if (this.checkUserCookie()) {
    //   return JSON.parse(this.cookieService.get("USER"));
    // } else {
    //   return null;
    // }
  }

  deleteCookie() {}

  checkUserCookie() {
    // return this.cookieService.check("USER");
  }

  get isLoggedInUser(){
    if (this.checkBrowser)
      return localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ?  localStorage.getItem('token') : null;
  }
}

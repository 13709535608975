import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateFormatWhatsOn } from '../../config/enum.config';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';
import * as moment from 'moment';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-whats-on-card',
  templateUrl: './whats-on-card.component.html',
  styleUrls: ['./whats-on-card.component.css']
})
export class WhatsOnCardComponent implements OnInit {

  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  @Input() item;
  @Input() classObject;
  @Input() otherData;

  @Input() config = {
    showLargeCard: false,
    showTooltip: false,
    showImg: true,
    showTitle: true,
    showSubTitle: true,
    showLargeTitle: true,
    showLargeTitleCard: true,
    showDate: true,
    showVenue: true,
    showPrice: true,
    showRemainingSeat: true,
    showFirstCTA: true,
    showSecondCTA: true,
    showBookNowCTA: true,
  };

  itemStatus: any[] = [
    { text: 'AVAILABLE', value: 0 },
    { text: 'ALMOST FULL', value: 1 },
    { text: 'SOLD OUT', value: 2 }
  ];

  isCalendarPage: boolean = false;

  constructor(public _sharedService: SharedService,
    public dialog: MatDialog,
    private storageService: StorageService,
    private router: Router) { }

  ngOnInit(): void {
    this._sharedService.getStatusOfOpeningShowsItem(this.item).subscribe((res) => {
      if (res?.value?.length > 0) {
        const findStatus = this.itemStatus.find((item) => item.value === res?.value[0].SoldoutStatus);
        this.item.SeatsRemaining = findStatus?.text;
      }
    }, (err) => {
      console.log(err);
    })

    this.isCalendarPage = this.router.url.includes('calendar')
  }

  getShowTimes(item) {
    const time = item.AddShowtime.map((item) => item.ShowTime).join(', ')
    return time ? ` | ${time}` : '';
  }

  getDateFormat(date: any, format = 'dd/mm/yyyy') {
    return moment.utc(date).format(format);
  }

  getShortText(value, lenthToShow = 80) {
    return value?.length > lenthToShow ? `${value.substr(0, lenthToShow)}..` : value;
  }

  getDateRange(item) {
    let string = '';
    if (!!!item?.PublishDate) return '';
    if (!!item?.ExpirationDate && item?.PublishDate != item?.ExpirationDate) {
      // string += 'From ';
    }
    string += this.getDateFormat(item?.PublishDate, DateFormatWhatsOn);
    if (item.OptionalEndDateText) {
      string += ` ` + item.OptionalEndDateText;
    } else if (!!item.ExpirationDate && item.PublishDate != item.ExpirationDate) {
      string += ` to ${this.getDateFormat(item.ExpirationDate, DateFormatWhatsOn)}`;
    }
    return string;
  }

  guest(item) {
    this._sharedService.openBookNowAsGuest(item?.EventID);
  }

  getVenueData(value, key = 'name') {
    return value.name || '';
  }

  openDialog(item): void {
    this.storageService.setSessionData('currShow', item?.EventID);
    const token = this.storageService.getSessionData('token');
    if (token) {
      const currShow = this.storageService.getSessionData('currShow');
      const currUser = JSON.parse(this.storageService.getSessionData('currUser'));
      const payload = {
        showId: currShow,
        token,
        name: currUser?.commonName,
        mobile: currUser?.defaultMobile,
        email: currUser?.defaultMail
      }
      this._sharedService.openMicrositeUrl(payload);
    } else {
      this.dialog.open(LoginDialogComponent, {
        width: '700px',
      });
    }
  }

  getTags(item) {
    const allTags = item.SelectedTags;
    let tagName = item.SelectedTags?.[0]?.name || '';
    return {
      fullStr: allTags?.map((item) => item?.name)?.join(', '),
      name: allTags?.length > 1 ? `${tagName} +${allTags.length - 1}` : tagName,
      value: allTags?.map((item) => item?.route)?.join(',')
    };
  }

  clickTag(item) {
    const query = this.getTags(item).value;
    if (this.otherData?.ViewCTALink?.data !== '') {
      const url = this.otherData?.ViewCTALink?.data || '/calendar';
      this.router.navigate([url], {
        queryParams: {
          tag: query
        }
      })
    }
  }

  onClickBookNow(item) {
    if (!this._sharedService.isProd()) {
      this.openDialog(item);
    } else {
      this.guest(item);
    }
  }

}

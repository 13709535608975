import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-website-notification',
  templateUrl: './website-notification.component.html',
  styleUrls: ['./website-notification.component.css']
})
export class WebsiteNotificationComponent implements OnInit {
  notificationData;
  @Output() closeNotification = new EventEmitter<boolean>()
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.getNotificationData();
  }

  getNotificationData() {
    this._sharedService.getCommonService().getlandingUrl(['NMACC-NOTIFICATION']).subscribe(
      (data: any) => {
        this.notificationData = data[0]?.data;

        const datepipe: DatePipe = new DatePipe('en-US')
        let today = Date.now();
        let expiryDate = this.notificationData.ExpirationDate.data
        let formattedExpDate = datepipe.transform(expiryDate, 'YYYY-MM-dd')
        let expiryTime = this.notificationData.ExpirationTime.data
        let newformattedExpDateTime = new Date(formattedExpDate + ' ' + expiryTime).getTime()
        if (newformattedExpDateTime < today || this.notificationData?.HideComponentOnSite?.data === 'true') {
          this.closeNotification.emit(true)
        }
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  closeNotificationClicked() {
    this.closeNotification.emit(true)
  }
  callWebNotificationCTEvent() {
    this._sharedService.callCTEvent('Header_Notification', '', '', 'DAS-BE')
  }
}

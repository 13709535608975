import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment';
import { jioIdConfig } from '../config/shared.constant';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.getSessionData('token');
    const findJioUrl = req.url.includes(environment.jioIdUrl) || req.url.includes(environment.jioIdApiUrl);
    const decrtyptTokenUrl = req.url.includes('decryptToken');
    const crmUrl = req.url.includes('CRM/api/data/v9.0/contacts');
    if(!!!token || (!findJioUrl && !decrtyptTokenUrl && !crmUrl)) return next.handle(req);
    let headers: any;
    if(decrtyptTokenUrl) {
      headers = new HttpHeaders({
        Authorization: "Bearer " + token,
     })
    } else if (crmUrl) {
      headers = new HttpHeaders({
        'MSCRM.SuppressDuplicateDetection': 'false'
      })
       //   headers = new HttpHeaders({
    //     Authorization: "Basic " + btoa('JWC.Dnydevadmin:D!g!@Life28'),
    //  })
    } else {
      headers = new HttpHeaders({
        'sso-token' : token,
        'app-name' : jioIdConfig.appName,
        'x-api-key': environment.xApiKey
      })
    }
    const request = req.clone({
      headers,
      withCredentials: true
    })
    return next.handle(request);
  }
}

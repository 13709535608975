import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventTitle'
})
export class EventTitlePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.EventTitle ? value.EventTitle.data : (value && value.CardTitle ? value.CardTitle.data : (value && value.CardHLText ? value.CardHLText.data : ''));
  }

}

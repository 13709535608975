// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env:"dev",
  getComponentUrl: "http://tccdev.ril.com/fetchinforedis/",
  staticUrlForImgNew: "http://tccdev.ril.com",
  getSubscriptionUrl: "http://tccdev.ril.com/fetchinfo/",
  getOtpUrl: "http://tccdev.ril.com/userprofile/",
  instaFeedUrl: "https://www.instagram.com/p/",
  exhibitorLogin: "http://scmpfdev.ril.com/eventexhibitor/login",
  recaptchaSiteKey: "6LfOTYQhAAAAAFnzJ8XEy4S_F1VDzdNhKNKdmKH_",
  jioIdUrl: "https://sit.id.jio.com/",
  jioIdApiUrl: "https://jioid-sit.jio.com/",
  micrositeUrl: "https://10.21.100.110/booking/",
  tokenUrl: "http://jwceadev.ril.com/",
  xApiKey: 'l7xx03f2780a11f34d228d6fb02adfe35055',
  jwcUrl: 'https://jwcdev.ril.com/',
  vistaWebClientUrl: "https://tickets.jioworldcentre.com/",
  vistaConnectApiToken: "28fcf9b5c731405e90adc4689c6777ed",
  crmUrl: "https://adfsedev.ril.com/",
  crmPassword : "D!g!@Life28",
  fonkeys: [],
  "newJioIdURL": "https://sit.id.jio.com/",
  "loyaltyUrl": "https://fonloyaltyqa.ril.com/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

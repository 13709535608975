import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
declare var grecaptcha: any;
@Component({
  selector: 'app-subscribe-section',
  templateUrl: './subscribe-section.component.html',
  styleUrls: ['./subscribe-section.component.css']
})
export class SubscribeSectionComponent implements OnInit, AfterViewInit {

  @Input() subscribeData;
  @Input() pageName;
  subSuccessMessage: string = "";
  subErrorMessage: string = "";
  subApiErrorMessage: string = "";
  @Input() colorList = {
    primaryColor: '#C63493', experiencesDataLineColor: '#C92C99', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#C92C99', footerHoverColor: '#FEF9FC', footerFillColor: '#FFFFFF', subscribeDataBgColor: "#2B193A", subscribeDataTitleColor: "#ffffff", subscribeDataLineColor: "#C92C99", subscribeDotColor: "#C92C99", subscribeLineColor: "#C92C99", subscribeTextColor: "#4B4847", momentsIconColor: "#C92C99", subscribe2DotColor: "#ffffff", subscribe2BorderColor: "#fff6", subscribe2TitleColor: "#ffffff", subscribe2LineColor: "#ffffff", headerDataHoverColor: "#BA3F96"
  };
  @Input() isNmacc: boolean;
  @Input() sectionClass?;
  inputColorClass: Map<string, string>;
  buttonColorClass: Map<string, string>;
  showSubMsg: boolean = false;
  showCaptcha: boolean = false;
  showInputBox: boolean = true;
  captchaError = '';
  emailId = "";
  captchaSiteKey: string;
  captchaToken : string = ""
  consentFlag = false;
  constructor(private _sharedService: SharedService, private route: ActivatedRoute) {
    this.inputColorClass = new Map([
      ["home", "grey_color"],
      ["home-2", "grey_color"],
      ["about-us", "grey_color"],
      ["performing-arts", "grey_color"],
      ["visual-arts", "grey_color"],
      ["calendar", "grey_color"],
      ["costume-arts", "grey_color"],
      ["venue-listing", "grey_color"],
      ["public-art", "grey_color"],
      ["contact-us", "grey_color"],
      ["legal", "grey_color"],
      ["sitemap", "grey_color"],
      ["blog-listing-page", "grey_color"],
      ["vip-lounge", "grey_color"],
      ["user-profile", "grey_color"],
      ["getting-here", "grey_color"],
      ["dining", "grey_color"],
      ["dining-detail", "grey_color"],
    ]);
    this.buttonColorClass = new Map([
      ["home", "split-pink"],
      ["home-2", "split-pink"],
      ["about-us", "split-pink"],
      ["performing-arts", "split-pink"],
      ["visual-arts", "split-pink"],
      ["calendar", "split-pink"],
      ["costume-arts", "split-pink"],
      ["venue-listing", "split-pink"],
      ["public-art", "split-pink"],
      ["contact-us", "split-pink"],
      ["legal", "split-pink"],
      ["sitemap", "split-pink"],
      ["blog-listing-page", "split-pink"],
      ["vip-lounge", "split-pink"],
      ["user-profile", "split-pink"],
      ["getting-here", "split-pink"],
      ["dining", "split-pink"],
      ["dining-detail", "split-pink"],
    ]);
    this._sharedService.getCommonService().showSubMsg.subscribe(
      (data) => {
        this.showSubMsg = data;
      }
    );
  }

  ngOnInit() {
    this.captchaSiteKey = environment.recaptchaSiteKey;
  }

  ngAfterViewInit() { 
    this.route.fragment.subscribe(fragment => {
      if (this.pageName === 'contact-us' && fragment === 'subscribe') {
        var inputField = document.getElementById('subscribe-field')
        inputField.focus()
      }
    });
  }

  callFn(form, $event, flag?) {
    if (!$event) return;
    if (flag) return this.captchaError = $event;
    this.captchaToken = $event
    this.captchaError = '';
    this.showCaptcha = false;
    this.showInputBox = true;
    this._sharedService.getCommonService().getSubscriptionDetails(this.emailId, 'nmacc', this.captchaToken, this.consentFlag).subscribe(
      (data) => {
        this.subErrorMessage = "";
        this.subSuccessMessage = data['message'];
        this.consentFlag = false;
        let activationLink = data['data'];
        let queryParamOne = activationLink.split('?')[1];
        const { subscriptionId } = this._sharedService.getCommonService().getSubscriptionIdAndFlag(queryParamOne);
        this._sharedService.getCommonService().setSubscriptionDetails(subscriptionId).subscribe(
          (unsubscribeData: any) => {
          console.log(unsubscribeData);
          const unsubscribeUrl = unsubscribeData?.data?.unsubscriptionUrl;
          this._sharedService.getCommonService().setSubscriptionTimer();
          if (activationLink) {
            let profile = {
              Email: this.emailId,
              "MSG-email": true
            };
            this._sharedService.getCommonService().getlandingUrl(['NMACC-NEWS-LETTER-SUBSCRIBE']).subscribe(
              (Data: any) => {
                // console.log({
                //   liferayData: Data[0]?.data
                // })
                const CTEventData = Data[0]?.data
                if (CTEventData?.IsActive?.data === 'true') {
                  let eventObj = {Activation_link: "" , Email_address:"" , Unsubscribe_link: ''}
                  CTEventData?.cards.forEach((property) => {
                    eventObj[property?.PropertyName?.data] = property?.PropertyValue?.data
                  })
                  
                  eventObj.Email_address=this.emailId;
                  eventObj.Activation_link=activationLink;
                  eventObj.Unsubscribe_link = unsubscribeUrl;
                  this._sharedService.getCleverTapService().createCTUserLogin(profile);
                  this._sharedService.getCleverTapService().recordSubscriptionEvent(CTEventData?.EventName?.data, eventObj);
                }
              },
              (error) => {
                console.log("Try after some time...");
              }
            )
          }
        })

          // console.log("Clevertap: ", (<any>window).clevertap.event);
          // console.log(eventObj);
        form.resetForm();
        grecaptcha.reset();
      },
      (error) => {
        grecaptcha.reset();
        this.subErrorMessage = "";
        this.subApiErrorMessage = "Try after some time..";
        this._sharedService.getCommonService().setSubscriptionTimer();
      }
    )
  }

  onSubscribeToWorld(form) {
    // return false;
    this.emailId = form.value.email;
    // console.log("emailId", this.emailId);
    if (!this.emailId) {
      this.subSuccessMessage = "";
      this.subErrorMessage = "Email is required";
    } else if (!form.valid) {
      this.subSuccessMessage = "";
      this.subErrorMessage = "Email must be a valid email address";
    } else if (!this.consentFlag) {
      this.subSuccessMessage = "";
      this.subErrorMessage = "Please accept the privacy policy before signing up for the newsletter.";
    } else {
      this.showCaptcha = true;
      this.showInputBox = false;
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';
import { jioIdConfig } from '../../../shared/config/shared.constant';
declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  // showLoginBtn = false;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  brandLogo;
  logoName: Map<string, string>;
  routePath: Map<string, string>;
  imgAltTxt: Map<string, string>;
  loginScreen;
  isUserLoggedIn;
  headerNavItems;
  exploreImgArr = ['jioworld-convention-centre', 'jioworld-garden', 'the-cultural-centre-header-image', 'dhirubhai-ambani-square-header-image', 'JioWorld-Drive​', 'Bay-Club', 'JioWorld-Plaza', 'JioWorld-Residences​', 'The-Clubhouse', 'jioworld-offices'];
  userDetails;
  private subscription;
  headerItem: any[] = []
  headerLeft: any[] = []
  headerRight: any[] = []
  headerHMB: any[] = []
  headerMobileFirst: any[] = []
  headerMobileSecond: any[] = []
  headerIcons: any[] = []
  signInLink = {
    "HeaderItemsOrder": {
      "data": "20"
    },
    "HeaderCTAText": {
      "data": "Sign In"
    },
    "HeaderCTALink": {
      "data": `${environment.jioIdUrl}?appIdentifier=${jioIdConfig.appName}&flowName=${jioIdConfig.signInFlowName}` 
    },
    "HeaderCTALinkWindowPreference": {
      "data": "[\"_self\"]"
    },
    "HeaderCTKeyName": {
      "data": "NMACC-HEADER-PRIMARY-LINKS-SIGN-IN"
    },
    "HideHeaderCTAText": {
      "data": this._sharedService.isProd()
    },
    "IsMobileView": {
      "data": "true"
    },
    "VisibleType": {
      "data": "[\"logoRight\"]"
    },
    "HeaderCTAType": {
      "data": "anotherPage"
    }
  }

  signOutLink = {
    "HeaderItemsOrder": {
      "data": "20"
    },
    "HeaderCTAText": {
      "data": "Sign Out"
    },
    "HeaderCTALink": {
      "data": `${environment.jioIdUrl}?appIdentifier=${jioIdConfig.appName}&flowName=${jioIdConfig.signOutFlowName}`
    },
    "HeaderCTALinkWindowPreference": {
      "data": "[\"_self\"]"
    },
    "HeaderCTKeyName": {
      "data": "NMACC-HEADER-PRIMARY-LINKS-SIGN-IN"
    },
    "HideHeaderCTAText": {
      "data": this._sharedService.isProd()
    },
    "IsMobileView": {
      "data": "true"
    },
    "VisibleType": {
      "data": "[\"logoRight\"]"
    },
    "HeaderCTAType": {
      "data": "anotherPage"
    }
  }

  environmentData  = environment;
  profileLink = {
    "HideHeaderCTAText": {
      "data": this._sharedService.isProd()
    },
    "IsMobileView": {
      "data": "true"
    },
    "VisibleType": {
      "data": "[\"logoRight\"]"
    },
    "HeaderCTAType": {
      "data": "anotherPage"
    }
  }
  constructor(public _sharedService: SharedService, private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.logoName = new Map([
      ["home", "logo-navbar-nmacc"],
      ["cec", "logo-navbar-cec"],
      ["tcc", "logo-navbar-tcc"],
      ["garden", "logo-navbar-garden"],
      ["das", "logo-navbar-das"],
      ["nmacc", "logo-navbar-nmacc"],

    ]);
    this.routePath = new Map([
      ["home", "/"],
      ["cec", "/jio-world-convention-centre"],
      ["garden", "/jio-world-garden"],
      ["tcc", "/the-cultural-centre"],
      ["das", "/dhirubhai-ambani-square"],
      ["nmacc", "/"]
    ]);
    this.imgAltTxt = new Map([
      ["home", "Nita Mukesh Ambani Cultural Centre Logo"],
      ["cec", "Jio World convention centre Logo"],
      ["tcc", "The uultural centre Logo"],
      ["garden", "Jio World garden Logo"],
      ["das", "Dhirubhai Ambani Square Logo"],
      ["nmacc", "Nita Mukesh Ambani Cultural Centre Logo"]
    ]);

    this._sharedService.getStorageService().userDetails.subscribe(
      (data: any) => {
        if (data) {
          this.isUserLoggedIn = this._sharedService.getStorageService().getSessionData('isLoggedIn');
          this.userDetails = this._sharedService.getStorageService().getSessionData('userDetails') ? JSON.parse(this._sharedService.getStorageService().getSessionData('userDetails')) : '';
        }
      }
    )
  }
  ngOnInit() {
    this.getHeaderContent()
    this.isUserLoggedIn = this._sharedService.getStorageService().getSessionData('isLoggedIn');
    this.userDetails = this._sharedService.getStorageService().getSessionData('userDetails') ? JSON.parse(this._sharedService.getStorageService().getSessionData('userDetails')) : '';
    this._sharedService.callNavFunction()
    this._sharedService.callNavigationScroll()

  }

  getHeaderContent() {
    let arr = [
      'NMACC-HEADER-FOOTER-COMPONENT'
    ]
    this.subscription = this._sharedService.getCommonService().getlandingUrl(arr).subscribe(
      (data: any) => {
        data[0]?.data?.cards.forEach(element => {
          if (element?.HideHeaderCTAText?.data == 'false') {
            this.headerItem.push(element)
          } else if (element?.HideHeaderIcon?.data == 'false') {
            this.headerIcons.push(element)
          }
        });
        this.brandLogo = data[0]?.data?.Logo
        this.brandLogo['ImageAltText'] = data[0]?.data?.ImageAltText
        this.setCtaLink()
        this.headerIcons.sort((a, b) => a?.HeaderIconOrder?.data - b?.HeaderIconOrder?.data)
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  callHeaderCTEvent(link?) {
    link ? this._sharedService.callCTEvent('Header_Primary_Links', '', '', link) : this._sharedService.callCTEvent('Header_Primary_Links');
  }
  closeHamMenu() {
    jQuery(document).ready(function () {
      jQuery('html').toggleClass('overflow-hidden');
      jQuery(".jwc_hamburger").trigger('click');
    })


  }
  logout() {
    this._sharedService.getStorageService().setSessionData('isLoggedIn', false);
    this._sharedService.getStorageService().setSessionData('userDetails', '');
    this.isUserLoggedIn = false;
    this.loginScreen = 'sendOtp';
  }
  userLoggedIn(val) {
    if (val) this.isUserLoggedIn = this._sharedService.getStorageService().getSessionData('isLoggedIn');
  }


  setCtaLink() {
    this.headerItem.sort((a, b) => {
      if (a.HeaderItemsOrder?.data > b.HeaderItemsOrder?.data) {
        return 1;
      } else if (a.HeaderItemsOrder?.data < b.HeaderItemsOrder?.data) {
        return -1;
      } else {
        return 0;
      }
    })
    this.headerItem.forEach(element => {
      if (element?.VisibleType?.data == "[\"logoLeft\"]") {
        this.headerLeft.push(element);

      } else if (element?.VisibleType?.data == "[\"logoRight\"]") {
        this.headerRight.push(element);

      } else if (element?.VisibleType?.data == "[\"HBM\"]") {
        this.headerHMB.push(element);

      }
      if (element?.IsMobileView?.data == 'true') {
        if (element?.VisibleType?.data == "[\"HBM\"]") {
          this.headerMobileSecond.push(element);
        } else {
          this.headerMobileFirst.push(element);
        }
      }
    });
  }

  logOut() {
    this._sharedService.getHttpService().logOut();
  }

  isNotLoggedinUser() {
    return this._sharedService.getStorageService().isLoggedInUser && this._sharedService.getStorageService().isLoggedInUser !== '' && this._sharedService.getStorageService().isLoggedInUser !== '""' ? false : true;
  }

}

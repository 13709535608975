import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { OnClickActionsTypeEnum } from '../../config/enum.config';
import { NewBannerData } from '../../config/interface.config';
import videojs from 'video.js';
interface BannerConfigData {
  outsiderDivClass : string;
  AutomaticSlideshowInterval: any;
  containerDivClass: string;
  isH1Heading: boolean;
}

const defaultBannerConfigData = {
  outsiderDivClass: 'jwc_banner_slider',
  AutomaticSlideshowInterval: 4000,
  containerDivClass: 'swiper-container',
  isH1Heading: false
}

const CTA = {
  first: {
    type: 'FirstCTAType',
    hide: 'FirstHideCTA',
    text: 'FirstCTAText',
    link: 'FirstCTALink',
  },
  second: {
    type: 'SecondCTAType',
    hide: 'SecondHideCTA',
    text: 'SecondCTAText',
    link: 'SecondCTALink',
  },
}
@Component({
  selector: 'app-banner-new',
  templateUrl: './banner-new.component.html',
  styleUrls: ['./banner-new.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BannerNewComponent implements OnInit {
  @Input() newBannerData: {
    cards: Array<NewBannerData>,
    AutomaticSlideshowInterval: any;
  };
  @Input() breadcrumbData?: any;
  @Input() bannerConfigData: BannerConfigData;
  AutomaticSlideshowInterval: number = 4000;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  screenWidth = 0;
  screenHeight = 0;

  constructor(public _sharedService: SharedService,
    private router: Router) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;  
    this.screenHeight = window.innerHeight;  
    // console.log({
    //   screenWidth: this.screenWidth,
    //   screenHeight: this.screenHeight
    // })
    if (this.newBannerData?.AutomaticSlideshowInterval && this.newBannerData?.AutomaticSlideshowInterval.data) {
      this.AutomaticSlideshowInterval = this.newBannerData.AutomaticSlideshowInterval.data.match(/(\d+)/)?.[0] * 1000 || 4000;
    }
    this._sharedService.initializeCustomJs();
    this._sharedService.load360Function();
    this.bannerConfigData = { ...defaultBannerConfigData, ...this.bannerConfigData};
  }
  callNewBannerCTEvent(eveName, linkClicked) {
    this._sharedService.callCTEvent(eveName, 'Banner', '', linkClicked);
  }

  getImageVideoUrl(item){
    return this.staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl || item?.UploadImageVideoForBanner?.document?.contentUrl || item?.UploadImageVideoForBanner);
  }

  getMobileImageVideoUrl(item){
    return this.staticUrlForImgNew + (item?.UploadMobileKMAImageVideo?.document?.contentUrl || item?.UploadMobileKMAImageVideoForBanner?.document?.contentUrl || item?.UploadMobileKMAImageVideoForBanner);
  }

  isImageTintOn(item) {
    return item?.ImageTintOn?.data === 'true';
  }

  getBackgroundImageVideoType(item) {
    return item?.BackgroundImageVideo?.data || item?.BackgroundImageVideoForBanner?.data || item?.BackgroundImageVideoForBanner;
  }

  getPlaceHolderImageForVideo(item) {
    return this.staticUrlForImgNew + (item?.PlaceholderImageForVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForVideoUploaded);
  }

  getMobilePlaceHolderImageForVideo(item) {
    return this.staticUrlForImgNew + (item?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForMobileVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForMobileVideoUploaded);
  }

  getImageAltText(item){
    return item?.ImageAltText?.data || item?.ImageAltTextForBanner?.data || item?.ImageAltTextForBanner;
  }

  getCtaType(item, type) {
    return item?.[CTA[type].type]?.data;
  }

  getCtaText(item, type){
    return item?.[CTA[type].text]?.data;
  }

  getCtaLink(item, type){
    return item?.[CTA[type].link]?.data;
  }

  isCtaHide(item, type){
    return item?.[CTA[type].hide]?.data =='true';
  }

  onClickHamburgerItem(item) {
    switch(item.clickType) {
      case OnClickActionsTypeEnum.DO_NOTHING :
        break;
      case OnClickActionsTypeEnum.NAVIGATE:
        this.router.navigateByUrl(item.routerLink);
        break;
    }
  }

  @HostListener('window:resize', ['$event'])  
  onResize(event) {  
   
    this.screenWidth = window.innerWidth;  
    this.screenHeight = window.innerHeight;  
    // console.log({
    //   resizeSreenWidth: this.screenWidth,
    //   resizeScreenHeight: this.screenHeight
    // })
  }

  public getDisplayValue(data: any): string {
    return data && typeof data === 'object' ? data.data : data;
  }

  videoPlayer
  ngAfterViewInit() {
    if (this._sharedService?.checkBrowser &&
      this.newBannerData?.cards?.some((ele:any) => ele?.UploadVideoURL?.data)) {
      this.videoPlayer = videojs('my-video',);
      this.videoPlayer?.on('canplay', () => {
        document?.querySelector('.hide-loader')?.classList?.remove('fade')
      });
    }
  }

  ngOnDestroy() {
    if (this.videoPlayer) {
      this.videoPlayer?.dispose();
    }
  }
}

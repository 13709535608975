import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-banner-sliding',
  templateUrl: './banner-sliding.component.html',
  styleUrls: ['./banner-sliding.component.css']
})
export class BannerSlidingComponent implements OnInit {
  @Input() slidingBanners
  staticUrlForImgNew: string = environment.staticUrlForImgNew;

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
  }

}

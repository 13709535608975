import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noOfDataToShow'
})
export class NoOfDataToShowPipe implements PipeTransform {

  transform(value: Array<any>, ...args: any[]): unknown {
      const [ showCount ] = args;
      if (showCount) {
        return value.slice(0, showCount);
      }
      return value;
  }

}

import { Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MetaPageKeys } from "../../config/shared.constant";
import { MetaService } from "../meta.service";
import { SharedService } from "../shared.service";


@Injectable({
    providedIn: 'root'
})
export class MetaResolver implements Resolve<any> {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
    constructor(private _sharedService: SharedService, 
        private customMetaService: MetaService,
        private router: Router,
        @Inject(Location) private location: any) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const platFormLocation = this.location._platformLocation;
        const currRoute = platFormLocation.pathname;
        //console.log({currRoute});
        // const currRoute = route.routeConfig.path;
        //this.customMetaService.updatePageTag('og:title', 'test ssr meta tags from guard');
      const findMetaKey = MetaPageKeys.find((item) => item.route === currRoute);
      //console.log({findMetaKey});
      if (!findMetaKey) return;
      return this._sharedService.getCommonService()
      .getlandingUrl([findMetaKey.metaKey])
      .pipe(map(
        (data: any) => {
          //console.log({data});
          if (!data?.[0]?.data) return;
          const metaObj = data[0].data;
          if(!metaObj) return;
          const metaLogo = metaObj?.EventMetaLogo?.image?.contentUrl ||  metaObj?.MetaLogo?.image?.contentUrl; 
          this.customMetaService.updatePageMeta({
            metaTitle: metaObj?.MetaTitle?.data,
            metaDesc: metaObj?.MetaDescription?.data,
            metaLogo: metaLogo,
            script: metaObj?.SchemaScript?.data,
            canonicalUrl: metaObj?.CanonicalURL?.data,
          });  
          return true;
        })
      )
    }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventTime'
})
export class EventTimePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.EventTime ? value.EventTime.data : (value && value.Time ? value.Time.data : '');
  }

}

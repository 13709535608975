import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClevertapService {

  constructor() { }
  recordSubscriptionEvent(eventName: string, eventObj: any) {
    // console.log('EventName: ', eventName);
    // console.log({
    //   eventName: eventObj
    // });
    return (<any>window)?.clevertap?.event?.push(eventName, eventObj);
  }
  createCTUserLogin(profile: any) {
    // console.log({profile});
    return (<any>window)?.clevertap?.onUserLogin?.push({ Site: profile });
  }
}

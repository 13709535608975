import { Component, OnInit, Input,ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @Input() faqData;
  @Input() colorList;
  @Input() sectionClass?;
  colorClass: Map<string, string>;
  constructor(public _sharedService: SharedService, private router: Router, private elementRef: ElementRef, private titleService: Title) {
    this.colorClass = new Map([
      ["#1E478E", "color-blue"],
      ["#C92C99", "color-pink"],
      ["#43B02A", "color-green"],
      ["#A67D54", "color-gold"]
    ]);
  }

  ngOnInit() {}

  callFaqSectionCTEvent(type, staticLink?) {
    switch(type){
      case 'sec':
      this._sharedService.callCTEvent('Section_Secondary_Links', this.faqData.ComponentHeaderTitle.data);
      break;
      case 'cont':
      this._sharedService.callCTEvent('Section_Content_Links', this.faqData.ComponentHeaderTitle.data, '', staticLink);
      break;  
    }
    
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CONST, jioIdConfig } from '../../config/shared.constant';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  jioLogin = {
    "LoginCTAText": {
      "data": "Login"
    },
    "LoginCTALink": {
      "data": `${environment.jioIdUrl}?appIdentifier=${jioIdConfig.appName}&flowName=${jioIdConfig.signInFlowName}` 
    },
    "LoginCTALinkWindowPreference": {
      "data": "[\"_self\"]"
    }
  }

  guestLogin = {
    "LoginCTAText": {
      "data": "Proceed as Guest"
    },
    "LoginCTALink": {
      "data": `${environment.micrositeUrl}?Guest=Y` 
    },
    "LoginCTALinkWindowPreference": {
      "data": "[\"_blank\"]"
    }
  }

  constructor(public dialogRef: MatDialogRef<LoginDialogComponent>,public _sharedService: SharedService,private storageService: StorageService) { }

  ngOnInit(): void {
  }

  login(event){
    const link = `${environment.jioIdUrl}?appIdentifier=${jioIdConfig.appName}&flowName=${jioIdConfig.signInFlowName}` 
    this._sharedService.checkCTALink(event,link,'_self');
  }

  guest(event){
    const currShow = this.storageService.getSessionData('currShow');
    const payload = {
      showId: currShow,
      Guest: 'Y'
    }
    this._sharedService.openMicrositeUrl(payload);
  }

}

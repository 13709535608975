import { Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MetaService } from "../meta.service";
import { SharedService } from "../shared.service";


@Injectable({
    providedIn: 'root'
})
export class VenueDetailsResolver implements Resolve<any> {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
    constructor(private _sharedService: SharedService, 
        private customMetaService: MetaService,
        private router: Router,
        @Inject(Location) private location: any) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const platFormLocation = this.location._platformLocation;
        const currRoute = platFormLocation.pathname;
        // console.log({currRoute});
        const slugUrl = route.params.slugId;
        return this._sharedService.getCommonService().getlandingUrl(['NMACC-SPACES'])
        .pipe(map((res: any) => {
            const data = res[0]?.data?.cards?.filter(ele => ele?.HideEventOnSite?.data !== 'true');
            const otherSpaces= [];
            const currentSpace = data.filter((item) => {
              if (item.SlugUrl?.data === slugUrl) {
                return true;
              } else {
                otherSpaces.push(item);
              }
              return item.SlugUrl?.data === slugUrl;
            })?.[0] || null;
            // console.log(currentSpace);
            const metaLogo = currentSpace?.EventMetaLogo?.image?.contentUrl ||  currentSpace?.MetaLogo?.image?.contentUrl; 
            this.customMetaService.updatePageMeta({
              metaTitle: currentSpace?.MetaTitle?.data,
              metaDesc: currentSpace?.MetaDescription?.data,
              metaLogo: metaLogo,
              script: currentSpace?.SchemaScript?.data,
              canonicalUrl: currentSpace?.CanonicalURL?.data,
            });
            return {
              currentSpace,
              otherSpaces
            };
          }))
      }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventDesc'
})
export class EventDescPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.EventDetailsContent ? value.EventDetailsContent.data : (value && value.CardDescription ? value.CardDescription.data : (value && value.CardShortText ? value.CardShortText.data : ''));
  }

}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';
import { SharedService } from '../../services/shared.service';

const defaultControls = {
  playToggle: true,
  captionsButton: false,
  chaptersButton: false,
  subtitlesButton: false,
  remainingTimeDisplay: false,
  progressControl: {
    seekBar: true
  },
  fullscreenToggle: false,
  playbackRateMenuButton: false,
}

@Component({
  selector: 'app-vjs-video',
  templateUrl: './vjs-video.component.html',
  styleUrls: ['./vjs-video.component.css']
})
export class VjsVideoComponent implements OnInit {
  option: any;
  currentVid: any;
  @Input() set options(option) {
    this.option = option;
    this.openVideo();
  };
  @ViewChild('target', { static: true }) target: ElementRef;

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
    if (this._sharedService.checkBrowser) {
      var videoDiv = document.getElementById('video-container');
      var video = document.createElement('video');
      video.id = 'my-video';
      video.className = 'video-js vjs-fluid vjs-big-play-centered';
      video.setAttribute('controls', 'controls');
      video.setAttribute('autoplay', 'true');
      video.setAttribute('preload', 'auto');
      video.setAttribute('width', '768px');
      video.setAttribute('height', '400px');
      videoDiv.appendChild(video);
      this.currentVid = videojs('my-video');
    }
  }

  openVideo() {
    if (this.currentVid ) {
      if (this.option.src.includes('.m3u8')) {
        this.currentVid.src({ type: 'application/x-mpegURL', src: this.option.src });
      } else if (this.option.src.includes('.ogv')) {
        this.currentVid.src({ type: 'application/ogg', src: this.option.src });
      } else {
        this.currentVid.src({ type: 'video/mp4', src: this.option.src });
      }

      this.currentVid.play();
    }
  }

}

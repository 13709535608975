import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {
  subHeaderListArr;
  @Input() set subHeaderList(value) {
    this.subHeaderListArr = value;
    this.selectedTitle = this.subHeaderListArr[0] ? this.subHeaderListArr[0].title : '';
    this.subHeaderSplit();
  }
  @Input() pageName;
  @Input() colorList;
  subHeaderList1 = [];
  subHeaderList2 = [];
  logoName: Map<string, string>;
  routeUrl: Map<string, string>;
  imgAltTxt: Map<string, string>;
  selectedTitle: string;
  constructor(private router: Router, public _sharedService: SharedService, private titleService: Title) {
    this.logoName = new Map([
      ["home", "logo-navbar-jwc"],
      ["cec", "logo-navbar-cec"],
      ["tcc", "logo-navbar-tcc"],
      ["garden", "logo-navbar-garden"],
      ["das", "logo-navbar-das"],
      ["new-das", "logo-navbar-das"],
      ["nmacc", "logo-navbar-nmacc"]
    ]);
    //page navigation
    this.routeUrl = new Map([
      ["cec", "/jio-world-convention-centre"],
      ["tcc", "/the-cultural-centre"],
      ["garden", "/jio-world-garden"],
      ["das", "/dhirubhai-ambani-square"],
      ["new-das", "/das"],
      ["nmacc", "/nita-ambani-cultural-centre"]
    ]);
    this.imgAltTxt = new Map([
      ["home", "Jio World centre Logo"],
      ["cec", "Jio World convention centre Logo"],
      ["tcc", "The uultural centre Logo"],
      ["garden", "Jio World garden Logo"],
      ["das", "Dhirubhai Ambani Square Logo"],
      ["nmacc", "Nita Ambani Cultural Centre Logo"]
    ]);
  }

  ngOnInit() {
    this._sharedService.callNavFunction();
    this._sharedService.callNavigationScroll();
    this._sharedService.callMobileSubnavigationText();
  }

  subHeaderSplit() {
    this.subHeaderList1 = [];
    this.subHeaderList2 = [];
    //preparing sub-header list displayed on both sides of logo
    for (let i = 0; i <= this.subHeaderListArr.length; i++) {
      if(i < this.subHeaderListArr.length / 2) {
        this.subHeaderList1.push(this.subHeaderListArr[i]);
      }else {
        this.subHeaderList2.push(this.subHeaderListArr[i]);
      }
    }
  }
  getSelectedTitle(event){
    this.selectedTitle = event.target.innerText;
  }
  customScroll(id){
    if(id==='book-passes') {
      setTimeout(function(){
        window.scrollTo(0, 400);
    }, 0);
    }
  }
  callCTSecondaryNavEvent(link) {
    this._sharedService.callCTEvent('Header_Secondary_Links', '', '', link);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../../../shared/services/shared.service';
declare var jQuery: any;

@Component({
  selector: 'app-das-header',
  templateUrl: './das-header.component.html',
  styleUrls: ['./das-header.component.css']
})
export class DasHeaderComponent implements OnInit {
  @Input() colorList?;
  isSticky: boolean;
  pageName;
  isNewHeaderDesk?;
  pageTitle: any;
  @Input() set newHeaderDesktop(val) {
    this.isNewHeaderDesk = val;
  }
  @Input() set dasSticky(val) {
    this.isSticky = val;
  }
  @Input() set dasPage(val) {
    this.pageName = val;
  }

  constructor(public _sharedService: SharedService, private router: Router, private titleService: Title) {
    this.pageTitle = this.titleService.getTitle();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pageTitle = this.titleService.getTitle();
      }
    })
  }

  ngOnInit() {
    this._sharedService.callNavFunction()
  }

  closeHamMenu() {
    jQuery("#jwc_mob_hamburger").trigger('click');
  }
  callCTPrimaryEvent(link?) {
    link ? this._sharedService.callCTEvent('Header_Primary_Links', '', '', link) : this._sharedService.callCTEvent('Header_Primary_Links');
  }
}

import { environment } from "../../../environments/environment";

export const InterceptorSkipHeader = "X-Skip-Interceptor";
export class constants {
  static readonly IN_DATE_FMT = "yyyy-mm-dd";
  static readonly IN_DATE_DELIMETER = "-";
  static readonly IN_DATE_TIME_FMT = `${constants.IN_DATE_FMT} hh:mm:ss`;
  static readonly OUT_DATE_FMT = "dd/MM/yyyy";
  static readonly OUT_DATE_DELIMETER = "/";
  static readonly EMAIL_REGEX = "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  static URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  static countryCodes = [ "+0","+1","+1242","+1246","+1264","+1268","+1284","+1340","+1345","+1441","+1473","+1649","+1664","+1670","+1671","+1684","+1758","+1767","+1784","+1787","+1809","+1868","+1869","+1876","+20","+212","+212","+213","+216","+218","+220","+221","+222","+223","+224","+225","+226","+227","+228","+229","+230","+231","+232","+233","+234","+235","+236","+237","+238","+239","+240","+241","+242","+242","+244","+245","+246","+248","+249","+250","+251","+252","+253","+254","+255","+256","+257","+258","+260","+261","+262","+263","+264","+265","+266","+267","+268","+269","+27","+290","+291","+297","+298","+299","+30","+31","+32","+33","+34","+350","+351","+352","+353","+354","+355","+356","+357","+358","+359","+36","+370","+371","+372","+373","+374","+375","+376","+377","+378","+380","+381","+385","+386","+387","+389","+39","+39","+40","+41","+420","+421","+423","+43","+44","+45","+46","+47","+48","+49","+500","+501","+502","+503","+504","+505","+506","+507","+508","+509","+51","+52","+53","+54","+55","+56","+57","+58","+590","+591","+592","+593","+594","+595","+596","+597","+598","+599","+60","+61","+62","+63","+64","+65","+66","+670","+672","+672","+673","+674","+675","+676","+677","+678","+679","+680","+681","+682","+683","+684","+686","+687","+688","+689","+690","+691","+692","+7","+70","+7370","+81","+82","+84","+850","+852","+853","+855","+856","+86","+880","+886","+90","+91","+92","+93","+94","+95","+960","+961","+962","+963","+964","+965","+966","+967","+968","+970","+971","+972","+973","+974","+975","+976","+977","+98","+992","+994","+995","+996","+998"];
}

export const jioIdConfig = {
  appName: 'JWC_NMACC_WBST',
  signInFlowName: 'web_site_signin',
  signOutFlowName: 'web_site_signout',
  manageFlowName : 'web_site_manage',
  friends_app_name: 'JWC_NMACC_Friends',
}

let apiEndPoint = {
  getLocationApi: "/geolocation/loc-by-ip",
  getredis: "/CU_SB_Redis/1.0.0/getRedisObject",
  getCache: "/EDC_NC_Business/1.0.0/GetSPdatafromCache",
  gettoken: "/gentoken/fetchToken",
  getrefreshtoken: "/gentoken/refreshToken",
  notification:'notification',
  login: "/login",
  logout: "/mylogout",
  // userImage: "/CU_NC_EmployeeInfo/1.0.0/UserImageSelf"
  userImage: "/CU_NC_EmployeeInfo/1.0.0/api/UserImage",
  addPoll: "/CI_NC_SPData/1.0.0/AddPollResults",
  calendarPost: '/CI_NC_SPData/1.0.0/AddEventParticipants',
  getDateWiseCalendarData:'/CI_NC_SPData/1.0.0/GetDateWiseCalendarData',
  corptest:'/CI_NC_SPData/1.0.0/AddPollResults',
  'getComponent': 'getComponent',
  'getSubscription': 'getSubscription',
  'setSubscription': 'setSubscription',
  'setUnSubscribe' : 'unSubscribe',
  'saveEnquiry' : 'saveEnquiry',
  'getNewsLetter': 'getNewsLetter',
  'getAllNewsLetters': 'getAllNewsLetters',
  'fetchPosition':'fetchPosition',
  'ActivePositions':'ActivePositions',
  getJioIdUserDetails: 'seco/jioid/user/display/details',
  crmContact: 'CRM/api/data/v9.0/contacts',
  crmAccessToken: 'adfs/oauth2/token',
  crmContacts:'api/data/v9.0/contacts',
  encryptToken: '/nmacc/genTokenGeneric',
  decryptToken: '/nmacc/decryptTokenGeneric',
  getOpeningShowsData: '/nmacc/saveOpeningShowsRedis',
  sendJioIdUserDetails :'seco/jioid/user/update',
  verifyJioIdUserDetails : 'seco/jioid/user/otp/verify',
  getVistaSessionDetails: 'WSVistaWebClient/OData.svc/Sessions',
  getActivityDetails: '/api/crmdetails',
  fonSendOtp: 'API/FonMemberCheck/CheckFonUser',
  fonVerifyOtp: 'API/FonMemberCheck/FONMemberVerify',
  fonSendOtpForProd: 'WEBSITE_API/FonMemberCheck/CheckFonUser',
  fonVerifyOtpForProd: 'WEBSITE_API/FonMemberCheck/FONMemberVerify'
};

export let CONST: Object = {
  apiEndPoint: apiEndPoint
};
export interface metaKeyType {
  name: string;
  route: string,
  metaKey: string
}

export const MetaPageKeys: Array<metaKeyType> = [
  {
    name: 'home',
    route: '/',
    metaKey: 'NMACC-META-TAGS'
  },
  {
    name: 'calendar',
    route: '/calendar',
    metaKey: 'NMACC-CALENDAR-META-TAGS'
  },
  {
    name: 'contactUs',
    route: '/contact-us',
    metaKey: 'NMACC-CONTACT-US-META-TAGS'
  },
  {
    name: 'aboutUs',
    route: '/about-us',
    metaKey: 'NMACC-ABOUT-US-META-TAGS'
  },
  {
    name: 'sitemap',
    route: '/sitemap',
    metaKey: 'NMACC-SITEMAP-META-TAGS'
  },
  {
    name: 'public-art',
    route: '/public-art',
    metaKey: 'NMACC-PUBLIC-ARTS-META-TAGS'
  },
  {
    name: 'legal',
    route: '/legal',
    metaKey: 'NMACC-LEGAL-META-TAGS'
  },
  {
    name: 'legalTabTerms',
    route: '/legal?tab=terms',
    metaKey: 'NMACC-LEGAL-TAB-TERMS-META-TAGS'
  },
  {
    name: 'sitemap',
    route: '/sitemap',
    metaKey: 'NMACC-SITEMAP-META-TAGS'
  },
  {
    name: 'das',
    route: '/das',
    metaKey: 'NMACC-DAS-META-TAGS'
  },
]


export const countryStateCityData = {
  "countries" : [{
   "name" : "India",
   "states":[
     {
       "state":"Andaman and Nicobar Islands",
       "cities":["Port Blair"]
     },
     {
       "state":"Andhra Pradesh",
       "cities":[
         "Visakhapatnam",
         "Vijayawada",
         "Guntur",
         "Nellore",
         "Kurnool",
         "Rajahmundry",
         "Kakinada",
         "Tirupati",
         "Anantapur",
         "Kadapa",
         "Vizianagaram",
         "Eluru",
         "Ongole",
         "Nandyal",
         "Machilipatnam",
         "Adoni",
         "Tenali",
         "Chittoor",
         "Hindupur",
         "Proddatur",
         "Bhimavaram",
         "Madanapalle",
         "Guntakal",
         "Dharmavaram",
         "Gudivada",
         "Srikakulam",
         "Narasaraopet",
         "Rajampet",
         "Tadpatri",
         "Tadepalligudem",
         "Chilakaluripet",
         "Yemmiganur",
         "Kadiri",
         "Chirala",
         "Anakapalle",
         "Kavali",
         "Palacole",
         "Sullurpeta",
         "Tanuku",
         "Rayachoti",
         "Srikalahasti",
         "Bapatla",
         "Naidupet",
         "Nagari",
         "Gudur",
         "Vinukonda",
         "Narasapuram",
         "Nuzvid",
         "Markapur",
         "Ponnur",
         "Kandukur",
         "Bobbili",
         "Rayadurg",
         "Samalkot",
         "Jaggaiahpet",
         "Tuni",
         "Amalapuram",
         "Bheemunipatnam",
         "Venkatagiri",
         "Sattenapalle",
         "Pithapuram",
         "Palasa Kasibugga",
         "Parvathipuram",
         "Macherla",
         "Gooty",
         "Salur",
         "Mandapeta",
         "Jammalamadugu",
         "Peddapuram",
         "Punganur",
         "Nidadavole",
         "Repalle",
         "Ramachandrapuram",
         "Kovvur",
         "Tiruvuru",
         "Uravakonda",
         "Narsipatnam",
         "Yerraguntla",
         "Pedana",
         "Puttur",
         "Renigunta",
         "Rajam",
         "Srisailam Project (Right Flank Colony) Township"
       ]
     },{
       "state":"Arunachal Pradesh",
       "cities":[
         "Naharlagun",
         "Pasighat"
       ]
     },
     {
       "state":"Assam",
       "cities":[
         "Guwahati",
         "Silchar",
         "Dibrugarh",
         "Nagaon",
         "Tinsukia",
         "Jorhat",
         "Bongaigaon City",
         "Dhubri",
         "Diphu",
         "North Lakhimpur",
         "Tezpur",
         "Karimganj",
         "Sibsagar",
         "Goalpara",
         "Barpeta",
         "Lanka",
         "Lumding",
         "Mankachar",
         "Nalbari",
         "Rangia",
         "Margherita",
         "Mangaldoi",
         "Silapathar",
         "Mariani",
         "Marigaon"
       ]
     },
     {
       "state":"Bihar",
       "cities":[
         "Patna",
         "Gaya",
         "Bhagalpur",
         "Muzaffarpur",
         "Darbhanga",
         "Arrah",
         "Begusarai",
         "Chhapra",
         "Katihar",
         "Munger",
         "Purnia",
         "Saharsa",
         "Sasaram",
         "Hajipur",
         "Dehri-on-Sone",
         "Bettiah",
         "Motihari",
         "Bagaha",
         "Siwan",
         "Kishanganj",
         "Jamalpur",
         "Buxar",
         "Jehanabad",
         "Aurangabad",
         "Lakhisarai",
         "Nawada",
         "Jamui",
         "Sitamarhi",
         "Araria",
         "Gopalganj",
         "Madhubani",
         "Masaurhi",
         "Samastipur",
         "Mokameh",
         "Supaul",
         "Dumraon",
         "Arwal",
         "Forbesganj",
         "BhabUrban Agglomeration",
         "Narkatiaganj",
         "Naugachhia",
         "Madhepura",
         "Sheikhpura",
         "Sultanganj",
         "Raxaul Bazar",
         "Ramnagar",
         "Mahnar Bazar",
         "Warisaliganj",
         "Revelganj",
         "Rajgir",
         "Sonepur",
         "Sherghati",
         "Sugauli",
         "Makhdumpur",
         "Maner",
         "Rosera",
         "Nokha",
         "Piro",
         "Rafiganj",
         "Marhaura",
         "Mirganj",
         "Lalganj",
         "Murliganj",
         "Motipur",
         "Manihari",
         "Sheohar",
         "Maharajganj",
         "Silao",
         "Barh",
         "Asarganj"
       ]
     },
     {
       "state":"Chandigarh",
       "cities":[
         "Chandigarh"
       ]
     },
     {
       "state":"Chhattisgarh",
       "cities":[
         "Raipur",
         "Bhilai Nagar",
         "Korba",
         "Bilaspur",
         "Durg",
         "Rajnandgaon",
         "Jagdalpur",
         "Raigarh",
         "Ambikapur",
         "Mahasamund",
         "Dhamtari",
         "Chirmiri",
         "Bhatapara",
         "Dalli-Rajhara",
         "Naila Janjgir",
         "Tilda Newra",
         "Mungeli",
         "Manendragarh",
         "Sakti"
       ]
     },
     {
       "state":"Dadra and Nagar Haveli",
       "cities":[
         "Silvassa"
       ]
     },
     {
       "state":"Delhi",
       "cities":[
         "Delhi",
         "New Delhi"
       ]
     },
     {
       "state":"Goa",
       "cities":[
         "Marmagao",
         "Panaji",
         "Margao",
         "Mapusa"
       ]
     },
     {
       "state":"Gujarat",
       "cities":[
         "Ahmedabad",
         "Surat",
         "Vadodara",
         "Rajkot",
         "Bhavnagar",
         "Jamnagar",
         "Nadiad",
         "Porbandar",
         "Anand",
         "Morvi",
         "Mahesana",
         "Bharuch",
         "Vapi",
         "Navsari",
         "Veraval",
         "Bhuj",
         "Godhra",
         "Palanpur",
         "Valsad",
         "Patan",
         "Deesa",
         "Amreli",
         "Anjar",
         "Dhoraji",
         "Khambhat",
         "Mahuva",
         "Keshod",
         "Wadhwan",
         "Ankleshwar",
         "Savarkundla",
         "Kadi",
         "Visnagar",
         "Upleta",
         "Una",
         "Sidhpur",
         "Unjha",
         "Mangrol",
         "Viramgam",
         "Modasa",
         "Palitana",
         "Petlad",
         "Kapadvanj",
         "Sihor",
         "Wankaner",
         "Limbdi",
         "Mandvi",
         "Thangadh",
         "Vyara",
         "Padra",
         "Lunawada",
         "Rajpipla",
         "Vapi",
         "Umreth",
         "Sanand",
         "Rajula",
         "Radhanpur",
         "Mahemdabad",
         "Ranavav",
         "Tharad",
         "Mansa",
         "Umbergaon",
         "Talaja",
         "Vadnagar",
         "Manavadar",
         "Salaya",
         "Vijapur",
         "Pardi",
         "Rapar",
         "Songadh",
         "Lathi",
         "Adalaj",
         "Chhapra"
                ]
     },
     {
       "state":"Haryana",
       "cities":[
         "Faridabad",
         "Gurgaon",
         "Hisar",
         "Rohtak",
         "Panipat",
         "Karnal",
         "Sonipat",
         "Yamunanagar",
         "Panchkula",
         "Bhiwani",
         "Bahadurgarh",
         "Jind",
         "Sirsa",
         "Thanesar",
         "Kaithal",
         "Palwal",
         "Rewari",
         "Hansi",
         "Narnaul",
         "Fatehabad",
         "Gohana",
         "Tohana",
         "Narwana",
         "Mandi Dabwali",
         "Charkhi Dadri",
         "Shahbad",
         "Pehowa",
         "Samalkha",
         "Pinjore",
         "Ladwa",
         "Sohna",
         "Safidon",
         "Taraori",
         "Mahendragarh",
         "Ratia",
         "Rania",
         "Sarsod"
       ]
     },
     {
       "state":"Himachal Pradesh",
       "cities":[
         "Shimla",
         "Mandi",
         "Solan",
         "Nahan",
         "Sundarnagar",
         "Palampur"
       ]
     },{
       "state":"Jammu and Kashmir",
       "cities":[
         "Srinagar",
         "Jammu",
         "Baramula",
         "Anantnag",
         "Sopore",
         "KathUrban Agglomeration",
         "Rajauri",
         "Punch",
         "Udhampur"
       ]
     },
     {
       "state":"Jharkhand",
       "cities":[
         "Dhanbad",
         "Ranchi",
         "Jamshedpur",
         "Bokaro Steel City",
         "Deoghar",
         "Phusro",
         "Adityapur",
         "Hazaribag",
         "Giridih",
         "Ramgarh",
         "Jhumri Tilaiya",
         "Saunda",
         "Sahibganj",
         "Medininagar (Daltonganj)",
         "Chaibasa",
         "Chatra",
         "Gumia",
         "Dumka",
         "Madhupur",
         "Chirkunda",
         "Pakaur",
         "Simdega",
         "Musabani",
         "Mihijam",
         "Patratu",
         "Lohardaga",
         "Tenu dam-cum-Kathhara"
       ]
     },
     {
       "state":"Karnataka",
       "cities":[
         "Bengaluru",
         "Hubli-Dharwad",
         "Belagavi",
         "Mangaluru",
         "Davanagere",
         "Ballari",
         "Tumkur",
         "Shivamogga",
         "Raayachuru",
         "Robertson Pet",
         "Kolar",
         "Mandya",
         "Udupi",
         "Chikkamagaluru",
         "Karwar",
         "Ranebennuru",
         "Ranibennur",
         "Ramanagaram",
         "Gokak",
         "Yadgir",
         "Rabkavi Banhatti",
         "Shahabad",
         "Sirsi",
         "Sindhnur",
         "Tiptur",
         "Arsikere",
         "Nanjangud",
         "Sagara",
         "Sira",
         "Puttur",
         "Athni",
         "Mulbagal",
         "Surapura",
         "Siruguppa",
         "Mudhol",
         "Sidlaghatta",
         "Shahpur",
         "Saundatti-Yellamma",
         "Wadi",
         "Manvi",
         "Nelamangala",
         "Lakshmeshwar",
         "Ramdurg",
         "Nargund",
         "Tarikere",
         "Malavalli",
         "Savanur",
         "Lingsugur",
         "Vijayapura",
         "Sankeshwara",
         "Madikeri",
         "Talikota",
         "Sedam",
         "Shikaripur",
         "Mahalingapura",
         "Mudalagi",
         "Muddebihal",
         "Pavagada",
         "Malur",
         "Sindhagi",
         "Sanduru",
         "Afzalpur",
         "Maddur",
         "Madhugiri",
         "Tekkalakote",
         "Terdal",
         "Mudabidri",
         "Magadi",
         "Navalgund",
         "Shiggaon",
         "Shrirangapattana",
         "Sindagi",
         "Sakaleshapura",
         "Srinivaspur",
         "Ron",
         "Mundargi",
         "Sadalagi",
         "Piriyapatna",
         "Adyar"
       ]
     },
     {
       "state":"Karnatka",
       "cities":[
         "Mysore"
       ]
     },
     {
       "state":"Kerala",
       "cities":[
         "Thiruvananthapuram",
         "Kochi",
         "Kozhikode",
         "Kollam",
         "Thrissur",
         "Palakkad",
         "Alappuzha",
         "Malappuram",
         "Ponnani",
         "Vatakara",
         "Kanhangad",
         "Taliparamba",
         "Koyilandy",
         "Neyyattinkara",
         "Kayamkulam",
         "Nedumangad",
         "Kannur",
         "Tirur",
         "Kottayam",
         "Kasaragod",
         "Kunnamkulam",
         "Ottappalam",
         "Thiruvalla",
         "Thodupuzha",
         "Chalakudy",
         "Changanassery",
         "Punalur",
         "Nilambur",
         "Cherthala",
         "Perinthalmanna",
         "Mattannur",
         "Shoranur",
         "Varkala",
         "Paravoor",
         "Pathanamthitta",
         "Peringathur",
         "Attingal",
         "Kodungallur",
         "Pappinisseri",
         "Chittur-Thathamangalam",
         "Muvattupuzha",
         "Adoor",
         "Mavelikkara",
         "Mavoor",
         "Perumbavoor",
         "Vaikom",
         "Palai",
         "Panniyannur",
         "Guruvayoor",
         "Puthuppally",
         "Panamattom"
       ]
     },
     {
       "state":"Madhya Pradesh",
       "cities":[
         "Indore",
         "Bhopal",
         "Jabalpur",
         "Gwalior",
         "Ujjain",
         "Sagar",
         "Ratlam",
         "Satna",
         "Murwara (Katni)",
         "Morena",
         "Singrauli",
         "Rewa",
         "Vidisha",
         "Ganjbasoda",
         "Shivpuri",
         "Mandsaur",
         "Neemuch",
         "Nagda",
         "Itarsi",
         "Sarni",
         "Sehore",
         "Mhow Cantonment",
         "Seoni",
         "Balaghat",
         "Ashok Nagar",
         "Tikamgarh",
         "Shahdol",
         "Pithampur",
         "Alirajpur",
         "Mandla",
         "Sheopur",
         "Shajapur",
         "Panna",
         "Raghogarh-Vijaypur",
         "Sendhwa",
         "Sidhi",
         "Pipariya",
         "Shujalpur",
         "Sironj",
         "Pandhurna",
         "Nowgong",
         "Mandideep",
         "Sihora",
         "Raisen",
         "Lahar",
         "Maihar",
         "Sanawad",
         "Sabalgarh",
         "Umaria",
         "Porsa",
         "Narsinghgarh",
         "Malaj Khand",
         "Sarangpur",
         "Mundi",
         "Nepanagar",
         "Pasan",
         "Mahidpur",
         "Seoni-Malwa",
         "Rehli",
         "Manawar",
         "Rahatgarh",
         "Panagar",
         "Wara Seoni",
         "Tarana",
         "Sausar",
         "Rajgarh",
         "Niwari",
         "Mauganj",
         "Manasa",
         "Nainpur",
         "Prithvipur",
         "Sohagpur",
         "Nowrozabad (Khodargama)",
         "Shamgarh",
         "Maharajpur",
         "Multai",
         "Pali",
         "Pachore",
         "Rau",
         "Mhowgaon",
         "Vijaypur",
         "Narsinghgarh"
       ]
     },
     {
       "state":"Maharashtra",
       "cities":[
         "Mumbai",
         "Pune",
         "Nagpur",
         "Thane",
         "Nashik",
         "Kalyan-Dombivali",
         "Vasai-Virar",
         "Solapur",
         "Mira-Bhayandar",
         "Bhiwandi",
         "Amravati",
         "Nanded-Waghala",
         "Sangli",
         "Malegaon",
         "Akola",
         "Latur",
         "Dhule",
         "Ahmednagar",
         "Ichalkaranji",
         "Parbhani",
         "Panvel",
         "Yavatmal",
         "Achalpur",
         "Osmanabad",
         "Nandurbar",
         "Satara",
         "Wardha",
         "Udgir",
         "Aurangabad",
         "Amalner",
         "Akot",
         "Pandharpur",
         "Shrirampur",
         "Parli",
         "Washim",
         "Ambejogai",
         "Manmad",
         "Ratnagiri",
         "Uran Islampur",
         "Pusad",
         "Sangamner",
         "Shirpur-Warwade",
         "Malkapur",
         "Wani",
         "Lonavla",
         "Talegaon Dabhade",
         "Anjangaon",
         "Umred",
         "Palghar",
         "Shegaon",
         "Ozar",
         "Phaltan",
         "Yevla",
         "Shahade",
         "Vita",
         "Umarkhed",
         "Warora",
         "Pachora",
         "Tumsar",
         "Manjlegaon",
         "Sillod",
         "Arvi",
         "Nandura",
         "Vaijapur",
         "Wadgaon Road",
         "Sailu",
         "Murtijapur",
         "Tasgaon",
         "Mehkar",
         "Yawal",
         "Pulgaon",
         "Nilanga",
         "Wai",
         "Umarga",
         "Paithan",
         "Rahuri",
         "Nawapur",
         "Tuljapur",
         "Morshi",
         "Purna",
         "Satana",
         "Pathri",
         "Sinnar",
         "Uchgaon",
         "Uran",
         "Pen",
         "Karjat",
         "Manwath",
         "Partur",
         "Sangole",
         "Mangrulpir",
         "Risod",
         "Shirur",
         "Savner",
         "Sasvad",
         "Pandharkaoda",
         "Talode",
         "Shrigonda",
         "Shirdi",
         "Raver",
         "Mukhed",
         "Rajura",
         "Vadgaon Kasba",
         "Tirora",
         "Mahad",
         "Lonar",
         "Sawantwadi",
         "Pathardi",
         "Pauni",
         "Ramtek",
         "Mul",
         "Soyagaon",
         "Mangalvedhe",
         "Narkhed",
         "Shendurjana",
         "Patur",
         "Mhaswad",
         "Loha",
         "Nandgaon",
         "Warud"
       ]
     },
     {
       "state":"Manipur",
       "cities":[
         "Imphal",
         "Thoubal",
         "Lilong",
         "Mayang Imphal"
       ]
     },
     {
       "state":"Meghalaya",
       "cities":[
         "Shillong",
         "Tura",
         "Nongstoin"
       ]
     },
     {
       "state":"Mizoram",
       "cities":[
         "Aizawl",
         "Lunglei",
         "Saiha"
       ]
     },
     {
       "state":"Nagaland",
       "cities":[
         "Dimapur",
         "Kohima",
         "Zunheboto",
         "Tuensang",
         "Wokha",
         "Mokokchung"
       ]
     },
     {
       "state":"Odisha",
       "cities":[
         "Bhubaneswar",
         "Cuttack",
         "Raurkela",
         "Brahmapur",
         "Sambalpur",
         "Puri",
         "Baleshwar Town",
         "Baripada Town",
         "Bhadrak",
         "Balangir",
         "Jharsuguda",
         "Bargarh",
         "Paradip",
         "Bhawanipatna",
         "Dhenkanal",
         "Barbil",
         "Kendujhar",
         "Sunabeda",
         "Rayagada",
         "Jatani",
         "Byasanagar",
         "Kendrapara",
         "Rajagangapur",
         "Parlakhemundi",
         "Talcher",
         "Sundargarh",
         "Phulabani",
         "Pattamundai",
         "Titlagarh",
         "Nabarangapur",
         "Soro",
         "Malkangiri",
         "Rairangpur",
         "Tarbha"
       ]
     },
     {
       "state":"Puducherry",
       "cities":[
         "Pondicherry",
         "Karaikal",
         "Yanam",
         "Mahe"
       ]
     },
     {
       "state":"Punjab",
       "cities":[
         "Ludhiana",
         "Patiala",
         "Amritsar",
         "Jalandhar",
         "Bathinda",
         "Pathankot",
         "Hoshiarpur",
         "Batala",
         "Moga",
         "Malerkotla",
         "Khanna",
         "Mohali",
         "Barnala",
         "Firozpur",
         "Phagwara",
         "Kapurthala",
         "Zirakpur",
         "Kot Kapura",
         "Faridkot",
         "Muktsar",
         "Rajpura",
         "Sangrur",
         "Fazilka",
         "Gurdaspur",
         "Kharar",
         "Gobindgarh",
         "Mansa",
         "Malout",
         "Nabha",
         "Tarn Taran",
         "Jagraon",
         "Sunam",
         "Dhuri",
         "Firozpur Cantt.",
         "Sirhind Fatehgarh Sahib",
         "Rupnagar",
         "Jalandhar Cantt.",
         "Samana",
         "Nawanshahr",
         "Rampura Phul",
         "Nangal",
         "Nakodar",
         "Zira",
         "Patti",
         "Raikot",
         "Longowal",
         "Urmar Tanda",
         "Morinda, India",
         "Phillaur",
         "Pattran",
         "Qadian",
         "Sujanpur",
         "Mukerian",
         "Talwara"
       ]
     },
     {
       "state":"Rajasthan",
       "cities":[
         "Jaipur",
         "Jodhpur",
         "Bikaner",
         "Udaipur",
         "Ajmer",
         "Bhilwara",
         "Alwar",
         "Bharatpur",
         "Pali",
         "Barmer",
         "Sikar",
         "Tonk",
         "Sadulpur",
         "Sawai Madhopur",
         "Nagaur",
         "Makrana",
         "Sujangarh",
         "Sardarshahar",
         "Ladnu",
         "Ratangarh",
         "Nokha",
         "Nimbahera",
         "Suratgarh",
         "Rajsamand",
         "Lachhmangarh",
         "Rajgarh (Churu)",
         "Nasirabad",
         "Nohar",
         "Phalodi",
         "Nathdwara",
         "Pilani",
         "Merta City",
         "Sojat",
         "Neem-Ka-Thana",
         "Sirohi",
         "Pratapgarh",
         "Rawatbhata",
         "Sangaria",
         "Lalsot",
         "Pilibanga",
         "Pipar City",
         "Taranagar",
         "Vijainagar, Ajmer",
         "Sumerpur",
         "Sagwara",
         "Ramganj Mandi",
         "Lakheri",
         "Udaipurwati",
         "Losal",
         "Sri Madhopur",
         "Ramngarh",
         "Rawatsar",
         "Rajakhera",
         "Shahpura",
         "Shahpura",
         "Raisinghnagar",
         "Malpura",
         "Nadbai",
         "Sanchore",
         "Nagar",
         "Rajgarh (Alwar)",
         "Sheoganj",
         "Sadri",
         "Todaraisingh",
         "Todabhim",
         "Reengus",
         "Rajaldesar",
         "Sadulshahar",
         "Sambhar",
         "Prantij",
         "Mount Abu",
         "Mangrol",
         "Phulera",
         "Mandawa",
         "Pindwara",
         "Mandalgarh",
         "Takhatgarh"
       ]
     },
     {
       "state":"Tamil Nadu",
       "cities":[
         "Chennai",
         "Coimbatore",
         "Madurai",
         "Tiruchirappalli",
         "Salem",
         "Tirunelveli",
         "Tiruppur",
         "Ranipet",
         "Nagercoil",
         "Thanjavur",
         "Vellore",
         "Kancheepuram",
         "Erode",
         "Tiruvannamalai",
         "Pollachi",
         "Rajapalayam",
         "Sivakasi",
         "Pudukkottai",
         "Neyveli (TS)",
         "Nagapattinam",
         "Viluppuram",
         "Tiruchengode",
         "Vaniyambadi",
         "Theni Allinagaram",
         "Udhagamandalam",
         "Aruppukkottai",
         "Paramakudi",
         "Arakkonam",
         "Virudhachalam",
         "Srivilliputhur",
         "Tindivanam",
         "Virudhunagar",
         "Karur",
         "Valparai",
         "Sankarankovil",
         "Tenkasi",
         "Palani",
         "Pattukkottai",
         "Tirupathur",
         "Ramanathapuram",
         "Udumalaipettai",
         "Gobichettipalayam",
         "Thiruvarur",
         "Thiruvallur",
         "Panruti",
         "Namakkal",
         "Thirumangalam",
         "Vikramasingapuram",
         "Nellikuppam",
         "Rasipuram",
         "Tiruttani",
         "Nandivaram-Guduvancheri",
         "Periyakulam",
         "Pernampattu",
         "Vellakoil",
         "Sivaganga",
         "Vadalur",
         "Rameshwaram",
         "Tiruvethipuram",
         "Perambalur",
         "Usilampatti",
         "Vedaranyam",
         "Sathyamangalam",
         "Puliyankudi",
         "Nanjikottai",
         "Thuraiyur",
         "Sirkali",
         "Tiruchendur",
         "Periyasemur",
         "Sattur",
         "Vandavasi",
         "Tharamangalam",
         "Tirukkoyilur",
         "Oddanchatram",
         "Palladam",
         "Vadakkuvalliyur",
         "Tirukalukundram",
         "Uthamapalayam",
         "Surandai",
         "Sankari",
         "Shenkottai",
         "Vadipatti",
         "Sholingur",
         "Tirupathur",
         "Manachanallur",
         "Viswanatham",
         "Polur",
         "Panagudi",
         "Uthiramerur",
         "Thiruthuraipoondi",
         "Pallapatti",
         "Ponneri",
         "Lalgudi",
         "Natham",
         "Unnamalaikadai",
         "P.N.Patti",
         "Tharangambadi",
         "Tittakudi",
         "Pacode",
         "O' Valley",
         "Suriyampalayam",
         "Sholavandan",
         "Thammampatti",
         "Namagiripettai",
         "Peravurani",
         "Parangipettai",
         "Pudupattinam",
         "Pallikonda",
         "Sivagiri",
         "Punjaipugalur",
         "Padmanabhapuram",
         "Thirupuvanam"
       ]
     },
     {
       "state":"Telangana",
       "cities":[
         "Hyderabad",
         "Warangal",
         "Nizamabad",
         "Karimnagar",
         "Ramagundam",
         "Khammam",
         "Mahbubnagar",
         "Mancherial",
         "Adilabad",
         "Suryapet",
         "Jagtial",
         "Miryalaguda",
         "Nirmal",
         "Kamareddy",
         "Kothagudem",
         "Bodhan",
         "Palwancha",
         "Mandamarri",
         "Koratla",
         "Sircilla",
         "Tandur",
         "Siddipet",
         "Wanaparthy",
         "Kagaznagar",
         "Gadwal",
         "Sangareddy",
         "Bellampalle",
         "Bhongir",
         "Vikarabad",
         "Jangaon",
         "Bhadrachalam",
         "Bhainsa",
         "Farooqnagar",
         "Medak",
         "Narayanpet",
         "Sadasivpet",
         "Yellandu",
         "Manuguru",
         "Kyathampalle",
         "Nagarkurnool"
       ]
     },
     {
       "state":"Tripura",
       "cities":[
         "Agartala",
         "Udaipur",
         "Dharmanagar",
         "Pratapgarh",
         "Kailasahar",
         "Belonia",
         "Khowai"
       ]
     },
     {
       "state":"Uttar Pradesh",
       "cities":[
         "Lucknow",
         "Kanpur",
         "Firozabad",
         "Agra",
         "Meerut",
         "Varanasi",
         "Allahabad",
         "Amroha",
         "Moradabad",
         "Aligarh",
         "Saharanpur",
         "Noida",
         "Loni",
         "Jhansi",
         "Shahjahanpur",
         "Rampur",
         "Modinagar",
         "Hapur",
         "Etawah",
         "Sambhal",
         "Orai",
         "Bahraich",
         "Unnao",
         "Rae Bareli",
         "Lakhimpur",
         "Sitapur",
         "Lalitpur",
         "Pilibhit",
         "Chandausi",
         "Hardoi",
         "Azamgarh",
         "Khair",
         "Sultanpur",
         "Tanda",
         "Nagina",
         "Shamli",
         "Najibabad",
         "Shikohabad",
         "Sikandrabad",
         "Shahabad, Hardoi",
         "Pilkhuwa",
         "Renukoot",
         "Vrindavan",
         "Ujhani",
         "Laharpur",
         "Tilhar",
         "Sahaswan",
         "Rath",
         "Sherkot",
         "Kalpi",
         "Tundla",
         "Sandila",
         "Nanpara",
         "Sardhana",
         "Nehtaur",
         "Seohara",
         "Padrauna",
         "Mathura",
         "Thakurdwara",
         "Nawabganj",
         "Siana",
         "Noorpur",
         "Sikandra Rao",
         "Puranpur",
         "Rudauli",
         "Thana Bhawan",
         "Palia Kalan",
         "Zaidpur",
         "Nautanwa",
         "Zamania",
         "Shikarpur, Bulandshahr",
         "Naugawan Sadat",
         "Fatehpur Sikri",
         "Shahabad, Rampur",
         "Robertsganj",
         "Utraula",
         "Sadabad",
         "Rasra",
         "Lar",
         "Lal Gopalganj Nindaura",
         "Sirsaganj",
         "Pihani",
         "Shamsabad, Agra",
         "Rudrapur",
         "Soron",
         "SUrban Agglomerationr",
         "Samdhan",
         "Sahjanwa",
         "Rampur Maniharan",
         "Sumerpur",
         "Shahganj",
         "Tulsipur",
         "Tirwaganj",
         "PurqUrban Agglomerationzi",
         "Shamsabad, Farrukhabad",
         "Warhapur",
         "Powayan",
         "Sandi",
         "Achhnera",
         "Naraura",
         "Nakur",
         "Sahaspur",
         "Safipur",
         "Reoti",
         "Sikanderpur",
         "Saidpur",
         "Sirsi",
         "Purwa",
         "Parasi",
         "Lalganj",
         "Phulpur",
         "Shishgarh",
         "Sahawar",
         "Samthar",
         "Pukhrayan",
         "Obra",
         "Niwai"
       ]
     },
     {
       "state":"Uttarakhand",
       "cities":[
         "Dehradun",
         "Hardwar",
         "Haldwani-cum-Kathgodam",
         "Srinagar",
         "Kashipur",
         "Roorkee",
         "Rudrapur",
         "Rishikesh",
         "Ramnagar",
         "Pithoragarh",
         "Manglaur",
         "Nainital",
         "Mussoorie",
         "Tehri",
         "Pauri",
         "Nagla",
         "Sitarganj",
         "Bageshwar"
       ]
     },
     {
       "state":"West Bengal",
       "cities":[
         "Kolkata",
         "Siliguri",
         "Asansol",
         "Raghunathganj",
         "Kharagpur",
         "Naihati",
         "English Bazar",
         "Baharampur",
         "Hugli-Chinsurah",
         "Raiganj",
         "Jalpaiguri",
         "Santipur",
         "Balurghat",
         "Medinipur",
         "Habra",
         "Ranaghat",
         "Bankura",
         "Nabadwip",
         "Darjiling",
         "Purulia",
         "Arambagh",
         "Tamluk",
         "AlipurdUrban Agglomerationr",
         "Suri",
         "Jhargram",
         "Gangarampur",
         "Rampurhat",
         "Kalimpong",
         "Sainthia",
         "Taki",
         "Murshidabad",
         "Memari",
         "Paschim Punropara",
         "Tarakeswar",
         "Sonamukhi",
         "PandUrban Agglomeration",
         "Mainaguri",
         "Malda",
         "Panchla",
         "Raghunathpur",
         "Mathabhanga",
         "Monoharpur",
         "Srirampore",
         "Adra"
       ]
     }
   ]
 }]
}

export const crmConfig = {
  dev: {
    "crmUrl": "https://adfsedev.ril.com/",
    "client_id": "99d75080-ac43-47cb-9748-8a9742373bca",
    "client_secret": "sbwOwvdtYNWi19eCRzyrlpR6rY2z3aJ1qQnfFwg_",
    "username": "IN\\JWC.Dnydevadmin",
    "grant_type": "password",
    "resource": "https://jwcdev.ril.com/api/data/v9.0/"
  },
  qa: {
    "crmUrl": "https://adfsprod.ril.com/",
    "client_id": "87844c72-99aa-43e3-8044-58a854653808",
    "client_secret": "tzNIMaQcGpAPNdnyra6ehCin0JnRBUtE6byBovpe",
    "username": "IN\\JWC.D365CRMPROD",
    "grant_type": "password",
    "resource": "https://jwccrm.ril.com/api/data/v9.0/"
  },
  prod: {
    "crmUrl": "https://adfsprod.ril.com/",
    "client_id": "87844c72-99aa-43e3-8044-58a854653808",
    "client_secret": "tzNIMaQcGpAPNdnyra6ehCin0JnRBUtE6byBovpe",
    "username": "IN\\JWC.D365CRMPROD",
    "grant_type": "password",
    "resource": "https://jwccrm.ril.com/api/data/v9.0/"
  }
}

export const subClassification = [
  {
    value: "Complaint",
    options: [
      "Ticketing",
      "Guest services",
      "Refund",
      "Bad transactions",
      "F&B"
    ]
  },
  {
    value: "Inquiry",
    options: [
      "Ticketing",
      "Miscellaneous",
      "Entry/exit/gates",
      "Show timings",
      "Show details",
      "Directions"
    ]
  },
  {
    value: "Venue Hire",
    options: [
      "GT/Studio/Cube",
      "Artist",
      "Corporate"
    ]
  },
  {
    value: "Collaboration",
    options: [
      "Artist",
      "Production",
      "Corporate",
      "Others"
    ]
  },
  {
    value: "Feedback",
    options: [
      "Appreciation",
      "Dissatisfaction"
    ]
  },
  {
    value: "Arthouse",
    options: [
      "Ticketing",
      "Refund/Transactions",
      "Miscellaneous",
      "Entry/Gate/Timing",
      "Show Information",
      "Available Slots"
    ]
  }
];

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, Subject, Subscription, timer, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedHttpService } from './shared-http.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  successfulSubscription = new Subject<boolean>();
  showSubMsg = new Subject<boolean>();
  private subscription: Subscription = new Subscription;
  private timer: Observable<any>;
  subscriptionFlag = new Subject<boolean>();
  subscriptionDetails = new Subject<any>();
  timerSubscribtion = new Subject<boolean>();
  timeLeft: number = 10;
  min: string | number;
  sec: string | number;
  loginTimerData = new Subject<any>();
  timerSub: Subscription;

  constructor(private _httpService: SharedHttpService, private activatedRoute: ActivatedRoute) { }
  getlandingData(componentId): Observable<any> {
    return this._httpService.getNoCred('getComponentUrl', 'getComponent', 'componentId=nmacc_' + componentId).pipe(catchError(() => of(null)));
  }
  getlandingUrl(keyArray) {
    let arr = [];
    keyArray.forEach((id) => {
      arr.push(this.getlandingData(id))
    });
    return forkJoin(arr);
  }

  getlandingDataNew(componentId): Observable<any> {
    return this._httpService.getNoCred('getComponentUrl', 'getComponent', 'componentId=nmacc_' + componentId).pipe(map((res: any) => res.data), catchError(() => of(null)));
  }
  getlandingUrlNew(keyArray) {
    let arr = [];
    keyArray.forEach((id) => {
      arr.push(this.getlandingDataNew(id))
    });
    return forkJoin(arr);
  }

  getCardOrder(array) {
    let sortedArray = [];
    return sortedArray = array.sort(function (a, b) {
      return (+a.CardOrdering.data.substring(2, 3)) - (+b.CardOrdering.data.substring(2, 3));
    })
  }

  getSubscriptionDetails(mailId: any, brandName: any, token:any, consentFlag?:boolean) {
    let param = 'mailId=' + mailId + '&brandName=' + brandName + '&grcToken=' + token + '&consentFlag=' + consentFlag;
    return this._httpService.getNoCred('getSubscriptionUrl', 'getSubscription', param);
  }
  setSubscriptionDetails(subscriptionId) {
    return this._httpService.getNoCred('getSubscriptionUrl', 'setSubscription', 'subscriptionId=' + subscriptionId);
  }
  setUnsubscriptionDetails(subscriptionId) {
    return this._httpService.getNoCred('getSubscriptionUrl', 'setUnSubscribe', 'subscriptionId=' + subscriptionId);
  }
  getSingleNewsArticlesData(newsId){
    return this._httpService.getNoCred('getComponentUrl', 'getNewsLetter', 'newsId=' + newsId)
  }
  getAllNewsArticlesData(){
    return this._httpService.getNoCred('getComponentUrl', 'getAllNewsLetters')
  }
  getInquiryData() {
    return this._httpService.getJson('./assets/config/inquiry.json')
  }
  getCecDownloadsData() {
    return this._httpService.getJson('./assets/config/cec-downloads.json')
  }
  submitInquiryDetails(payload: any) {
    let body = JSON.stringify(payload);
    return this._httpService.postLogin('getSubscriptionUrl', 'saveEnquiry', body);
  }
  submitCareersInquiryDetails(payload: any) {
    return this._httpService.postLogin('getSubscriptionUrl', 'saveCareer', payload, null, true);
  }
  submitContactUsDetails(payload: any) {
    let body = JSON.stringify(payload);
    return this._httpService.postLogin('getSubscriptionUrl', 'saveContactUs', payload, null, true);
  }
  submitMobileDetails(payload: any) {
    let body = JSON.stringify(payload);
    return this._httpService.postLogin('getOtpUrl', 'getOtp', body);
  }
  validateOtp(payload: any) {
    // let body = JSON.stringify(payload);
    return this._httpService.postLogin('getOtpUrl', 'verifyOtp', payload);
  }
  saveUserDetails(payload: any) {
    return this._httpService.postLogin('getOtpUrl', 'saveUserDetails', payload);
  }
  registerUser(payload: any) {
    return this._httpService.postLogin('getOtpUrl', 'register', payload);
  }
  validateRegistrationOtp(payload: any) {
    // let body = JSON.stringify(payload);
    return this._httpService.postLogin('getOtpUrl', 'conformRegistration', payload);
  }
  onSubsciptionPageLoad() {
    let url = window.location.href;
    if (url.indexOf("?") != -1) {
      let queryParamOne = url.split('?')[1];
      if (queryParamOne.indexOf("=") != -1) {
        const {decryptedQuery, subscriptionId, flag } = this.getSubscriptionIdAndFlag(queryParamOne);
        if (decryptedQuery.indexOf("flag") != -1) {
          if (flag == 'subscription') {
            this.onSubscriptionCall(subscriptionId);
          } else {
            this.onUnsubscriptionCall(subscriptionId);
          }
        }
      }
    }
  }

  getSubscriptionIdAndFlag(input) {
    let queryParamOne = input.split('?')[1];
    let queryParam = input.split("=")[0];
    let decryptedQuery = atob(queryParam);
    let subscriptionId = decryptedQuery.split("&")[0].split("=")[1];
    let flag = decryptedQuery.split("&")[1].split("=")[1];
    return {decryptedQuery, subscriptionId, flag};
  }

  onSubscriptionCall(subscriptionId) {
    this.setSubscriptionDetails(subscriptionId).subscribe(
      (data) => {
        // console.log(data['data'].mailId);
        // console.log(data['data'].unsubscriptionUrl);
        this.successfulSubscription.next(true);
        this.subscriptionFlag.next(true);
        this.subscriptionDetails.next(data);
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
  }
  onUnsubscriptionCall(subscriptionId) {
    this.setUnsubscriptionDetails(subscriptionId).subscribe(
      (data) => {
        // console.log(data['message']);
        this.successfulSubscription.next(true);
        this.subscriptionFlag.next(false);
        this.subscriptionDetails.next(data);
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
  }
  setSubscriptionTimer() {
    this.showSubMsg.next(true);
    this.timer = timer(10000);
    this.subscription = this.timer.subscribe(() => {
      this.showSubMsg.next(false);
    });
  }
  
  startTimer() {
    const source = timer(1000, 1000);
    let timerVal;
    this.timerSub = source.subscribe(val => {
      timerVal = this.timeLeft - val;
      if (val < 11) {
        this.min = (Math.floor(timerVal / 60) >= 10) ? Math.floor(timerVal / 60) : '0' + Math.floor(timerVal / 60);
        this.sec = (timerVal % 60 >= 10) ? timerVal % 60 : '0' + timerVal % 60;
        let data = {
          "min": this.min,
          "sec": this.sec
        }
        this.loginTimerData.next(data);
        // console.log("val inside if: ", val);
      } else {
        // this.otpResent = false;
        this.timerSub.unsubscribe();
        // console.log("val inside else: ", val);
        this.timerSubscribtion.next(false);
      }
    });
  }
  ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  submitCustomerCareDetails(payload: any) {
    let body = JSON.stringify(payload);
    return this._httpService.postLogin('staticUrlForImgNew', '/nmacc/saveCustomerCareData', payload, null, true);
  }


  submitContactUsOrEnquiryDetails(payload: any) {
    let body = JSON.stringify(payload);
    return this._httpService.postLogin('staticUrlForImgNew', '/nmacc/saveEnquiryorContactUs', payload, null, true);
  }

  submitReservationForm(payload: any) {
    let body = JSON.stringify(payload);
    return this._httpService.postLogin('staticUrlForImgNew', '/nmacc/reserveRestaurant', payload, null, true);
  }

  submitCollabForm(payload: any) {
    let body = JSON.stringify(payload);
    return this._httpService.postLogin('staticUrlForImgNew', '/nmacc/collaboration', payload, null, true);
  }

}

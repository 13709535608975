import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { OnClickActionsTypeEnum } from '../../config/enum.config';
import { BannerDataType, BreadcrumbDataType } from '../../config/interface.config';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-page-picture',
  templateUrl: './page-picture.component.html',
  styleUrls: ['./page-picture.component.css']
})
export class PagePictureComponent implements OnInit {

  @Input() bannerData: BannerDataType;
  @Input() sectionClass?: string;
  @Input() kmaClass?: string;
  @Input() breadcrumbClass?: string;
  @Input() breadcrumbData?: any;
  @Input()showBreadcrumb = true
  @Output() onClickBreadcrumb: EventEmitter<BreadcrumbDataType> = new EventEmitter();
  @Input() useStatic?:boolean = false;
  @Input() staticImageUrl?:string
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  autoplayBgImage: boolean = false;
  threesixtyVideoImage: boolean = false;

  ngOnChanges(){
    
    console.log("Banner Data: ",this.bannerData?.UploadImageVideo?.document?.contentUrl)
  }
  constructor(private router: Router, private _sharedService: SharedService) { }

  ngOnInit(): void {
    
    // this.staticUrlForImgNew = this._sharedService.getBaseUrl();
  }

  onClickBreadcrumbText(input: BreadcrumbDataType){
    if(input.clickType && input.clickType != OnClickActionsTypeEnum.DO_NOTHING){
      switch (input.clickType) {
        case OnClickActionsTypeEnum.NAVIGATE :
          if(input.routerLink)
            this.router.navigateByUrl(input.routerLink);
          break;  
        case OnClickActionsTypeEnum.DOWNLOAD: 
          // download functionality
          break; 
        case OnClickActionsTypeEnum.RESPONSE_BACK: 
          this.onClickBreadcrumb.emit(input);
          break;     
        default: 
          this.onClickBreadcrumb.emit(input);
          break;
      }
    }
  }

}

import { Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MetaService } from "../meta.service";
import { SharedService } from "../shared.service";


@Injectable({
    providedIn: 'root'
})
export class ShowDetailsResolver implements Resolve<any> {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
    constructor(private _sharedService: SharedService, 
        private customMetaService: MetaService,
        private router: Router,
        @Inject(Location) private location: any) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const platFormLocation = this.location._platformLocation;
        const currRoute = platFormLocation.pathname;
        const slugUrl = route.params.eventName;
        return this._sharedService.getOpeningShowsData()
        .pipe(map((res: any) => {
            const data = res;
            
            let currentEvent;
            const otherEvents= [];
            res.forEach((cardItem) => {
              cardItem.AddCardDetails?.forEach((item) => {
                if (item.SlugUrl === slugUrl) {
                  currentEvent = item;
                } else if (!item.HideEventOnSite) {
                  otherEvents.push(item); 
                }
              })
            })
           
            // res.flatMap((item: any) => item.AddCardDetails).forEach((item) => {
            //   if(!item?.HideEventOnSite) {
            //     otherEvents.push(item);
            //   }
            // });


            // console.log(currentEvent);
            const metaLogo = currentEvent?.EventMetaLogo ||  currentEvent?.MetaLogo; 
            this.customMetaService.updatePageMeta({
              metaTitle: currentEvent?.MetaTitle,
              metaDesc: currentEvent?.MetaDescription,
              metaKeywords : currentEvent?.MetaKeywords,
              metaLogo: metaLogo,
              script: currentEvent?.SchemaScript,
              canonicalUrl: currentEvent?.CanonicalURL,
            });
            return {
              currentEvent,
              otherEvents
            };
          }))
      }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { observable, Observable } from 'rxjs';
import { StorageService } from '../../shared/services/storage.service';
import { CONST } from '../../shared/config/shared.constant';
import { SharedService } from '../../shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private router: Router,
    private sharedService: SharedService,
    private storageService: StorageService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return new Observable((observer) => {
    //   if(this.storageService.getSessionData('token')) {
    //   this.sharedService.getHttpService().postLogin('jioIdApiUrl', CONST["apiEndPoint"]['getJioIdUserDetails'], '')
    //   .subscribe((res :any) => {
    //     // console.log(res);
    //     if(res.status === 'SUCCESS' && res.userDetails) {
    //       // console.log(res);
    //       this.storageService.setSessionData('currUser', JSON.stringify(res.userDetails));
    //       observer.next(true)
    //      } else {
    //       observer.next(false);
    //       this.sharedService.getHttpService().logOut();
    //       this.router.navigateByUrl('');
    //      }
    //   }, (error) => {
    //     console.log(error);
    //     observer.error(error);
    //     this.sharedService.getHttpService().logOut();
    //     this.router.navigateByUrl('');
    //   })
       
    // } else {
    //   observer.next(false);
    // };
    // })

    if (this.isAuthenticated()) {
      return true
    } else {
      this.storageService.delelteSessionData('token');
      this.storageService.delelteSessionData('currUser');
      this.storageService.delelteSessionData('Wso2Token');
      this.router.navigate(['']);
      return false
    }
  }

  isAuthenticated() {
    return !!this.storageService.getSessionData('token')
  }

}

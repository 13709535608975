import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthGuardGuard } from "./core/guards/auth-guard.guard";
import { BlogListingPageComponent } from "./modules/blog/blog-listing-page/blog-listing-page.component";
import { P404Component } from "./shared/components/error/404.component";
import { InquiryThankYouComponent } from "./shared/components/inquiry-home/inquiry-thank-you/inquiry-thank-you.component";
import { MetaResolver } from "./shared/services/resolvers/meta-resolver.service";
import { ShowDetailsResolver } from "./shared/services/resolvers/show-details-resolver.service";
import { VenueDetailsResolver } from "./shared/services/resolvers/venue-details-resolver.service";
import { ComingSoonComponent } from "./shared/components/coming-soon/coming-soon.component";
import { IsNotProdGuard } from "./core/guards/is-not-prod.guard";
import { BlogDetailsComponent } from "./modules/blog/blog/blog.component";
import { BlogDetailsResolver } from "./shared/services/resolvers/blog-details-resolver.service";

const routes = [
    {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
        data: {
            title: 'Nita Mukesh Ambani Cultural Centre - Mumbai, India',
            CTLinkClicked: 'Nita Ambani Cultural Centre',
            sticky: false,
            page: 'nmacc',
            notificationId: '1'
        },
        resolve: {
            data: MetaResolver
        }
    },
    {
        path: "legal",
        loadChildren: () => import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
        resolve: {
            data: MetaResolver
        }
    },
    {
        path: "sitemap",
        loadChildren: () => import('./modules/sitemap/sitemap.module').then((m) => m.SitemapModule),
        resolve: {
            data: MetaResolver
        }
    },
    {
        path: "venue-details/:slugId",
        loadChildren: () => import('./modules/venue-listing/venue-listing.module').then((m) => m.VenueListingModule),
        resolve: {
            data: VenueDetailsResolver
        }
    },
    {
        path: 'calendar',
        loadChildren: () => import('./modules/nmacc-calendar/nmacc-calendar.module').then((m) => m.NmaccCalendarModule),
        resolve: {
            data: MetaResolver
        },
    },
    {
        path: 'contact-us',
        loadChildren: () => import('./modules/contact-us/contact-us.module').then((m) => m.ContactUsModule),
        resolve: {
            data: MetaResolver
        }

    },
    {
        path: 'collab-form',
        loadChildren: () => import('./modules/collab-form/collab-form.module').then((m) => m.CollabFormModule),
        canActivate: [IsNotProdGuard]
    },

    {
        path: 'enquiry',
        loadChildren: () => import('./modules/enquiry-page/enquiry-page.module').then((m) => m.EnquiryPageModule),
        resolve: {
            data: MetaResolver
        },
        canActivate: [IsNotProdGuard]
    },

    {
        path: 'about-us',
        loadChildren: () => import('./modules/about-us/about-us.module').then((m) => m.AboutUsModule),
        resolve: {
            data: MetaResolver
        }

    },
    {
        path: 'stories/:slugUrl',
        loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
        component: BlogDetailsComponent,
        resolve: {
            data: BlogDetailsResolver
        }
    },
    {
        path: 'blog-listing-page',
        loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
        component: BlogListingPageComponent
    },
    {
        path:'blog-listing-page/:slugId',
        loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
        component:BlogListingPageComponent
        
    },
    {
        path: 'public-art',
        loadChildren: () => import('./modules/public-arts/public-arts.module').then((m) => m.PublicArtsModule),
        resolve: {
            data: MetaResolver
        }

    },
    {
        path: 'dining',
        loadChildren: () => import('./modules/dining/dining.module').then((m) => m.DiningModule)
    },
    {
        path: 'thank-you/:type',
        component: InquiryThankYouComponent
    },
    {
        path: 'vip-lounge',
        loadChildren: () => import('./modules/vip-lounge/vip-lounge.module').then((m) => m.VipLoungeModule),
    },
    // {
    //     path:'customer-care',
    //     loadChildren: () => import('./modules/customer-care/customer-care.module').then((m) => m.CustomerCareModule),
    // },
    {
        path: 'profile',
        loadChildren: () => import('./modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
        canActivate: [AuthGuardGuard]
    },

    {
        path: ':eventType/:eventName',
        loadChildren: () => import('./modules/show-details/show-details.module').then((m) => m.ShowDetailsModule),
        resolve: {
            data: ShowDetailsResolver
        }

    },
    {
        path: 'getting-here',
        loadChildren: () => import('./modules/getting-here/getting-here.module').then((m) => m.GettingHereModule),
    },
    { path: "not-found", component: P404Component, data: { title: 'Nita Mukesh Ambani Cultural Centre - Page not found', page: 'home', sticky: true } },
    // { path: "coming-soon", component: ComingSoonComponent, data: { title: 'Nita Mukesh Ambani Cultural Centre - Coming Soon', page: 'home', sticky: true } },
    { path: "**", redirectTo: '/not-found' },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'corrected',
            // initialNavigation: 'enabled'
        })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
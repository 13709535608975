import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
   @Input() colorList;
   @Input() islandingPage;
   staticUrlForImgNew: string = environment.staticUrlForImgNew;
   currentYear: number;
   copyRightText:any
   subscription: any;
   articleData: any;
   encryptedId: string;
   footerItem:any[]=[]
   footerTopLinks:any[]=[]
   footerBarLinks:any[]=[]
   footerIcons:any[]=[]

   constructor(public _sharedService: SharedService) {}

   ngOnInit() {
      this.getHeaderContent()
      this.setCtaLinks();
   }
 
   getHeaderContent() {
     let arr=[
       'NMACC-HEADER-FOOTER-COMPONENT'
     ]
     this.subscription = this._sharedService.getCommonService().getlandingUrl(arr).subscribe(
       (data: any) => {
         data[0]?.data?.cards.forEach(element => {
            if (element?.HideFooterCTAText?.data=='false') {
               this.footerItem.push(element)
            } else if (element?.HideFooterIcon?.data== 'false') {
               this.footerIcons.push(element)
            }
         });
         this.copyRightText = data[0]?.data?.CopyrightText?.data
         this.setCtaLinks()
         this.footerIcons.sort((a,b)=>a?.FooterIconOrder?.data - b?.FooterIconOrder?.data)
       },
       (error) => {
         console.log("Try after some time..");
       }
     )
   }

   callCTFooterEvent(link?) {
      link ? this._sharedService.callCTEvent('Footer_Primary_Links', '', '', link) : this._sharedService.callCTEvent('Footer_Primary_Links');
   }

   setCtaLinks() {
      this.footerItem.sort((a, b) => {
         if(a.FooterItemsOrder?.data > b.FooterItemsOrder?.data){
           return 1;
         } else if (a.FooterItemsOrder?.data < b.FooterItemsOrder?.data){
           return -1;
         } else {
           return 0;
         }
       })

       this.footerItem.forEach(element=>{
         if (element?.IsFooterTopLink?.data == "true") {
            this.footerTopLinks.push(element);   
           } else{
              this.footerBarLinks.push(element);   
           }
       })
      }
   }
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, interval } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { environment } from 'src/environments/environment';
import { jioIdConfig } from '../../config/shared.constant';

@Component({
  selector: 'app-fon-presales',
  templateUrl: './fon-presales.component.html',
  styleUrls: ['./fon-presales.component.css']
})
export class FonPresalesComponent implements OnInit, OnDestroy {
  @Input() item;
  private timerSubscription: Subscription;
  private subscription: Subscription;
  fonData: any;
  isTimerOn: boolean = false
  subHeadingIndex: number = 0
  fonForm: FormGroup;
  apiErrorMsg: string

  constructor(public _sharedService: SharedService, private formBuilder: FormBuilder, private storageService: StorageService) { }

  ngOnInit(): void {
    this.initiateForm()
    this.getPageContent()
  }

  private initiateForm(): void {
    this.fonForm = this.formBuilder.group({
      mobileOrMemberID: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(10)]],
      otp1: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      otp2: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      otp3: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      otp4: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      otp5: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      otp6: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });

    this.getControl('mobileOrMemberID').valueChanges.subscribe(value => {
      this.apiErrorMsg = ''
    });
  }

  public handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text');
    const otpLength = 6;

    if (pasteData.length === otpLength) {
      for (let i = 0; i < otpLength; i++) {
        const control = this.fonForm.get('otp' + (i + 1));
        if (control) {
          control.setValue(pasteData.charAt(i));
        }
      }
    }
  }

  get otp() {
    let otp = ''
    for (let i = 1; i <= 6; i++) {
      otp += (this.getControl(`otp${i}`).value == null ? '' : this.getControl(`otp${i}`).value);
    }
    return otp;
  }

  public getControl(controlName: string): any {
    return this.fonForm?.get(controlName);
  }

  private getPageContent(): void {
    let hashKeys = ["SHOW-DETAILS-FON-PRE-SALES"]

    this.subscription = this._sharedService.getCommonService().getlandingUrl(hashKeys).subscribe(
      (data: any) => {
        this.fonData = data[0] ? data[0].data : data[0];
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  public sendOtp(subHeadingIndex): void {
    if (this.isTimerOn) return;
    const payload = `{"identifier":"${this.getControl('mobileOrMemberID')?.value}"}`
    this._sharedService.fonSendOtp(payload).subscribe(
      (data: any) => {
        if (data?.status === 'success') {
          this.isTimerOn = true
          this.subHeadingIndex = subHeadingIndex
          this.startTimer(subHeadingIndex);
          this._sharedService.callPreSalesJS()
        } else if (data?.status === 'failed') {
          this.subHeadingIndex = 3
        } else {
          this.apiErrorMsg = data?.message
        }
      },
      (error) => {
        this.apiErrorMsg = "Try after some time.."
        console.log("Try after some time..");
      }
    )
  }

  public contactUs(link):void{
    if (!this.isTimerOn) {
      window.open(link)
    }
  }

  public verifyOtp(): void {
    const payload = `{"identifier":"${this.getControl('mobileOrMemberID')?.value}","otp":"${this.otp}"}`

    this._sharedService.fonVerifyOtp(payload).subscribe(
      (data: any) => {
        if (data?.status === 'success') {
          const payload = {
            showId: this.item?.EventID,
            name: data?.name,
            mobile: data?.mobileno,
            email: data?.emailid
          }
          this._sharedService.openMicrositeUrl(payload);
        } else {
          this.apiErrorMsg = data?.message
        }
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  startTimer(index: number) {
    const subHeading = this.fonData.cards[index]?.ComponentHeaderSubTitle?.data
    const timeMatch = subHeading.match(/(\d{2}) : (\d{2})/);
    if (timeMatch) {
      let [minutes, seconds] = timeMatch.slice(1, 3).map(Number);

      this.timerSubscription = interval(1000).subscribe(() => {
        if (seconds === 0 && minutes === 0) {
          this.timerSubscription.unsubscribe();
          this.isTimerOn = false
        } else {
          if (seconds === 0) {
            minutes--;
            seconds = 59;
          } else {
            seconds--;
          }
          const updatedTime = `<span class="timer">${this.pad(minutes)} : ${this.pad(seconds)}</span>`;
          this.fonData.cards[index].ComponentHeaderSubTitle.data = subHeading.replace(/\d{2} : \d{2}/, updatedTime);
        }
      });
    }
  }

  pad(num: number): string {
    return num.toString().padStart(2, '0');
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.timerSubscription?.unsubscribe();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-benefits-wrapper',
  templateUrl: './benefits-wrapper.component.html',
  styleUrls: ['./benefits-wrapper.component.css']
})
export class BenefitsWrapperComponent implements OnInit {
  @Input() benefitsWrapperData;
  @Input() styleConfig?;
  @Input() showPreRegisterSection?;
  staticUrlForImgNew = environment.staticUrlForImgNew;

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
  }

}

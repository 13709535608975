import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnClickActionsTypeEnum } from 'src/app/shared/config/enum.config';
import { BreadcrumbDataType } from 'src/app/shared/config/interface.config';
import { environment } from './../../../../environments/environment';
import * as moment from 'moment';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogDetailsComponent implements OnInit {
  private subscription
  staticUrlForImgNew = environment.staticUrlForImgNew
  subscribeData;
  readsData;
  currentBlogData: any;

  nmaccColorList = {
    primaryColor: '#C63493', experiencesDataLineColor: '#C92C99', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#C92C99', footerHoverColor: '#FEF9FC', footerFillColor: '#FFFFFF', subscribeDataBgColor: "#2B193A", subscribeDataTitleColor: "#ffffff", subscribeDataLineColor: "#C92C99", subscribeDotColor: "#C92C99", subscribeLineColor: "#C92C99", subscribeTextColor: "#4B4847", momentsIconColor: "#C92C99", subscribe2DotColor: "#ffffff", subscribe2BorderColor: "#fff6", subscribe2TitleColor: "#ffffff", subscribe2LineColor: "#ffffff", headerDataHoverColor: "#BA3F96"
  }
  breadcrumbData: Array<BreadcrumbDataType> = [
    {
      text: 'Nita Mukesh Ambani Cultural Centre',
      clickType: OnClickActionsTypeEnum.NAVIGATE,
      routerLink: '/',
      isNewWindow: false
    },
    {
      text: '',
      clickType: OnClickActionsTypeEnum.DO_NOTHING,
    }];

    constructor(public _sharedService: SharedService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.data.subscribe((res: any) => {
      this.currentBlogData = res?.data?.currentBlogData;

      // this.otherBlogData = res?.data?.otherBlogData;
      if (this.currentBlogData) {
        this.getPageContent()
        this.breadcrumbData[1].text = this.currentBlogData?.CardTitle
        this.currentBlogData.Date = moment.utc(this.currentBlogData?.Date).format('Do MMM YYYY')
      } else {
        this.router.navigate(['**'])
      }
    })
  }

  getPageContent() {
    let nMACCKeyArray = [
      'NMACC-BLOG-SUBSCRIBE',
      'NMACC-READ',
    ];

    this.subscription = this._sharedService.getCommonService().getlandingUrl(nMACCKeyArray).subscribe(
      (data: any) => {
        this.subscribeData = data[0].data;
        this.readsData = data[1].data;

        this._sharedService.initializeCustomJs();
        this._sharedService.callNormalSlider();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { filter } from 'jszip';
import { BreadcrumbDataType } from '../../../shared/config/interface.config';
import { CategoryType, OnClickActionsTypeEnum } from '../../../shared/config/enum.config';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blog-listing-page',
  templateUrl: './blog-listing-page.component.html',
  styleUrls: ['./blog-listing-page.component.css']
})
export class BlogListingPageComponent implements OnInit {
  staticUrlForImgNew = environment.staticUrlForImgNew
  CategoryType=CategoryType
  private subscription
  filteredArticles=[]
  bannerData
  filterData
  readsData
  subscribeData
  categories

  nmaccColorList = {
    primaryColor: '#C63493', experiencesDataLineColor: '#C92C99', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#C92C99', footerHoverColor: '#FEF9FC', footerFillColor: '#FFFFFF', subscribeDataBgColor: "#2B193A", subscribeDataTitleColor: "#ffffff", subscribeDataLineColor: "#C92C99", subscribeDotColor: "#C92C99", subscribeLineColor: "#C92C99", subscribeTextColor: "#4B4847", momentsIconColor: "#C92C99", subscribe2DotColor: "#ffffff", subscribe2BorderColor: "#fff6", subscribe2TitleColor: "#ffffff", subscribe2LineColor: "#ffffff", headerDataHoverColor: "#BA3F96"
  }

  bannerConfigData={
    outsiderDivClass: 'jwc_banner_slider jwc_banner_slider jwc_banner_wvideo_slider swiper_only_dots jwc_swiper_normal',
    AutomaticSlideshowInterval: 4000,
    containerDivClass: 'swiper-container'
  }

  breadcrumbData: Array<BreadcrumbDataType> = [
    {
      text: 'Nita Mukesh Ambani Cultural Centre',
      clickType: OnClickActionsTypeEnum.NAVIGATE,
      routerLink: '/',
      isNewWindow: false
    },
    {
      text: `<strong> Blog</strong>`,
      clickType: OnClickActionsTypeEnum.DO_NOTHING,
    }];
    blogs: any = [];
    type: string = 'ALL';

  constructor(public _sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.getPageContent();
  }

  getPageContent() {
    let nMACCKeyArray = [
      'NMACC-BLOG-LISTING-BANNER',
      'NMACC-BLOG-LISTING-BY-CATEGORY',
      'NMACC-READ',
      'NMACC-BLOG-LISTING-SUBSCRIBE',
      'Categories',
      'Blogs'
    ];

    this.subscription = this._sharedService.getCommonService().getlandingUrl(nMACCKeyArray).subscribe(
      (data: any) => {
        this.bannerData = data[0].data;
        this.filterData = data[1].data;
        this.readsData = data[2].data
        this.subscribeData = data[3].data
        this.categories = data[4].data
        this.blogs = data[5].data;
        this.route.paramMap.subscribe((data: any) => {
          if(data?.params?.slugId) {
            this.type = this.categories.find((item) => item.CardSlugURL === data?.params?.slugId)?.CardText;
            this.itemSelected(0 , this.type)
          } else {
            this.itemSelected(0 , this.type)
          }
          this._sharedService.callFractionSlider();
          this._sharedService.callNormalSlider();
          this._sharedService.callLoader();
        })
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  itemSelected(index, category) {
   this.categories.forEach(ele=>ele.selected=false)
   //this.categories[index].selected=true
   const findSelectedCat = this.categories.find((item) => item.CardText?.toLowerCase() === category.toLowerCase());
   if(findSelectedCat)
      findSelectedCat.selected = true;
   this.filterArticles(category.toUpperCase())
  }

  filterArticles(category){
    if (category=='ALL') {
      this.filteredArticles=this.blogs
    } else {
      this.filteredArticles=this.blogs.filter(ele=>ele?.Category?.toUpperCase().includes(category)
      )
    }
  }

  selectCategory(category) {
    this.router.navigateByUrl('/blog-listing-page/' + category?.CardSlugURL);
  }
  
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-subscribe-ribbon',
  templateUrl: './subscribe-ribbon.component.html',
  styleUrls: ['./subscribe-ribbon.component.css']
})
export class SubscribeRibbonComponent implements OnInit {
@Input()subscribeRibbonData;
  constructor() { }

  ngOnInit(): void {
  }

}

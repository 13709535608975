import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { OnClickActionsTypeEnum } from '../../config/enum.config';
import { SharedService } from '../../services/shared.service';

interface BannerConfigData {
  outsiderDivClass : string;
  AutomaticSlideshowInterval: any;
  containerDivClass: string;
}

const defaultBannerConfigData = {
  outsiderDivClass: 'jwc_banner_slider',
  AutomaticSlideshowInterval: 4000,
  containerDivClass: 'swiper-container'
}

@Component({
  selector: 'app-banner-with-thumbail',
  templateUrl: './banner-with-thumbail.component.html',
  styleUrls: ['./banner-with-thumbail.component.css']
})
export class BannerWithThumbailComponent implements OnInit {

  @Input() newBannerData;
  @Input() breadcrumbData?: any;
  @Input() bannerConfigData: BannerConfigData;
  AutomaticSlideshowInterval: number = 4000;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  screenWidth = window.innerWidth;

  constructor(public _sharedService: SharedService, private router:Router) { }

  ngOnInit(): void {
    if (this.newBannerData.AutomaticSlideshowInterval && this.newBannerData.AutomaticSlideshowInterval.data) this.AutomaticSlideshowInterval = this.newBannerData.AutomaticSlideshowInterval.data.match(/(\d+)/)?.[0] * 1000 || 4000;
    // this._sharedService.initializeCustomJs();
    this._sharedService.load360Function();
    this.bannerConfigData = { ...defaultBannerConfigData, ...this.bannerConfigData}
    // console.log({
    //   newBannerData: this.newBannerData
    // });
  }
  callNewBannerCTEvent(eveName, linkClicked) {
    this._sharedService.callCTEvent(eveName, 'Banner', '', linkClicked);
  }

  onClickHamburgerItem(item) {
    switch(item.clickType) {
      case OnClickActionsTypeEnum.DO_NOTHING :
        break;
      case OnClickActionsTypeEnum.NAVIGATE:
        this.router.navigateByUrl(item.routerLink);
        break;
    }
  }

}

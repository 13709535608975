import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { OnClickActionsTypeEnum } from '../../config/enum.config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BannerComponent implements OnInit {
  @Input() newBannerData;
  @Input() breadcrumbData?: any;
  AutomaticSlideshowInterval: number = 4000;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  screenWidth = 0;
  screenHeight = 0;
  constructor(public _sharedService: SharedService, private router: Router) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    // console.log(this.newBannerData);
    if (this.newBannerData?.AutomaticSlideshowInterval?.data)
      this.AutomaticSlideshowInterval = this.newBannerData.AutomaticSlideshowInterval.data.match(/(\d+)/)?.[0] * 1000 || 4000;
    this._sharedService.initializeCustomJs();
    this._sharedService.load360Function();
  }

  getImageVideoUrl(item) {
    return this.staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl || item?.UploadImageVideoForBanner);
  }

  callNewBannerCTEvent(eveName, linkClicked) {
    this._sharedService.callCTEvent(eveName, 'Banner', '', linkClicked);
  }

  isImageTintOn(item) {
    return item?.ImageTintOn?.data === 'true';
  }

  getMobileImageVideoUrl(item) {
    return this.staticUrlForImgNew + (item?.UploadMobileKMAImageVideo?.document?.contentUrl || item?.UploadMobileKMAImageVideoForBanner);
  }

  getPlaceHolderImageForVideo(item) {
    return this.staticUrlForImgNew + (item?.PlaceholderImageForVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForVideoUploaded);
  }

  getBackgroundImageVideoType(item) {
    return item?.BackgroundImageVideo?.data || item?.BackgroundImageVideoForBanner;
  }

  getImageAltText(item) {
    return item?.ImageAltText?.data || item?.ImageAltTextForBanner;
  }

  getMobilePlaceHolderImageForVideo(item) {
    return this.staticUrlForImgNew + (item?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForMobileVideoUploaded);
  }

  public getDisplayValue(data: any): string {
    return data && typeof data === 'object' ? data.data : data;
  }

  onClickHamburgerItem(item) {
    switch (item.clickType) {
      case OnClickActionsTypeEnum.DO_NOTHING:
        break;
      case OnClickActionsTypeEnum.NAVIGATE:
        this.router.navigateByUrl(item.routerLink);
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

}

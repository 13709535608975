import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-theatres',
  templateUrl: './theatres.component.html',
  styleUrls: ['./theatres.component.css']
})
export class TheatresComponent implements OnInit {
@Input() theatreData
staticUrlForImgNew = environment.staticUrlForImgNew
  constructor() { }

  ngOnInit(): void {
  }
  

}
